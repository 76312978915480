import { FetchItemsFilters } from '../../../../../../../../types';
import { IndexRequestAction } from '../../reducers/indexRequestReducer';

import { FILTER_ITEMS } from '../../infiniteIndexRequestConstants';

function filterItemsAction(nextFilters: FetchItemsFilters): IndexRequestAction {
  return {
    type: FILTER_ITEMS,
    nextFilters
  };
}

export default filterItemsAction;
