import { useCallback } from 'react';
import { useQueryClient, QueryKey } from 'react-query';
import isEmpty from 'lodash/isEmpty';

import {
  InfiniteIndexQueryBaseNodeType,
  InfiniteIndexQueryData,
  UpdateInfiniteIndexQueryItemCacheAction
} from '../../useInfiniteIndexQuery.types';

import { getItemIndexes } from './utils/getItemIndexes';
import { getUpdatedQueryData } from './utils/getUpdatedQueryData';

interface UpdateInfiniteIndexQueryItemCacheOptions {
  fullCacheKey: QueryKey;
  scope: string;
}

function useUpdateInfiniteIndexQueryItemCache<
  ItemType extends InfiniteIndexQueryBaseNodeType
>({ fullCacheKey, scope }: UpdateInfiniteIndexQueryItemCacheOptions) {
  const queryClient = useQueryClient();

  return useCallback<UpdateInfiniteIndexQueryItemCacheAction<ItemType>>(
    ({ selector, updateFunction }) => {
      queryClient.cancelQueries(fullCacheKey);

      const previousQueryData =
        queryClient.getQueryData<InfiniteIndexQueryData<ItemType>>(
          fullCacheKey
        );

      if (!previousQueryData || isEmpty(previousQueryData?.pages)) {
        return null;
      }

      const { pageIndex, itemIndex } = getItemIndexes({
        data: previousQueryData,
        selector,
        scope
      });

      const updatedQueryData = getUpdatedQueryData<ItemType>({
        updateFunction,
        previousQueryData,
        scope,
        pageIndex,
        itemIndex
      });

      if (!updatedQueryData) {
        return null;
      }

      queryClient.setQueryData<InfiniteIndexQueryData<ItemType>>(
        fullCacheKey,
        updatedQueryData
      );

      return () => {
        queryClient.setQueryData<InfiniteIndexQueryData<ItemType>>(
          fullCacheKey,
          previousQueryData
        );
      };
    },
    [fullCacheKey, queryClient, scope]
  );
}

export default useUpdateInfiniteIndexQueryItemCache;
