import { BffApiUpdateItemError, ID } from '../../../../types';
import { ProductCategoryID } from '../../../products/productsTypes';
import { ProjectID } from '../../../projects/projectsTypes';
import { TaskID } from '../../../tasks/tasksTypes';
import {
  MatchResultUUID,
  MatchSingleObjectByDetectedBoundingCacheKeys
} from '../../matchResultsTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { MatchResultBffUrl } from '../../MatchResultBffUrl';

interface MatchSingleObjectByDetectedBoundingOptions {
  cacheKeys?: MatchSingleObjectByDetectedBoundingCacheKeys;
}

type MatchSingleObjectByDetectedBoundingResponseType = {
  record: {
    id: MatchResultUUID;
  };
};

export type MatchSingleObjectByDetectedBoundingInput = {
  boundingId: ID;
  productCategoryId?: ProductCategoryID;
  projectId?: ProjectID;
  taskId?: TaskID;
  labels?: ID[];
};

export type MatchSingleObjectByDetectedBoundingError = BffApiUpdateItemError;

function useMatchSingleObjectByDetectedBounding({
  cacheKeys
}: MatchSingleObjectByDetectedBoundingOptions) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<
    MatchSingleObjectByDetectedBoundingInput,
    MatchSingleObjectByDetectedBoundingResponseType,
    MatchSingleObjectByDetectedBoundingError
  >({
    cacheKeys,
    url: MatchResultBffUrl.matchSingleObjectByDetectedBounding()
  });

  return {
    matchSingleObjectByDetectedBoundingData: postQueryData,
    matchSingleObjectByDetectedBoundingError: postQueryError,
    matchSingleObjectByDetectedBoundingLoading: postQueryLoading,
    matchSingleObjectByDetectedBoundingErrorMessage: postQueryErrorMessage,
    matchSingleObjectByDetectedBounding: postQuery,
    matchSingleObjectByDetectedBoundingReset: postQueryReset
  };
}

export default useMatchSingleObjectByDetectedBounding;
