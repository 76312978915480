import React from 'react';

import { LightboxItem } from '../../LightboxWrapper.types';

import { TextWithTooltip } from '../../../../helpers/TextWithTooltip';

import { TooltipPlacement } from '../../../../helpers/tooltips/tooltipsConstants';
import { Translate } from '../../../../helpers/Translate';
import { CheckPermissions } from '../../../../helpers/CheckPermissions';

import { words } from '../../../../locales/keys';

import { LightboxWrapperPermissions } from '../../lightboxWrapperConstants';

interface LightboxWrapperBaseImageTitleProps {
  imageItem: LightboxItem;
  index: number;
  imagesCount: number;
}

function LightboxWrapperBaseImageTitle({
  imageItem,
  index,
  imagesCount
}: LightboxWrapperBaseImageTitleProps) {
  if (!imageItem) {
    return null;
  }

  const hasSizes = Boolean(imageItem.width && imageItem.height);

  return (
    <div className="flex-1 p-1.5 text-left w-[50vw]">
      <div className="text-sm overflow-hidden ">
        {imageItem.name ? (
          <div className="text-white truncate">
            <TextWithTooltip
              text={imageItem.name}
              className="font-medium"
              tooltipPlacement={TooltipPlacement.BOTTOM}
            />
          </div>
        ) : null}

        <div className="text-gray-300 truncate">
          {hasSizes ? (
            <CheckPermissions
              action={
                LightboxWrapperPermissions.READ_LIGHTBOX_TITLE_IMAGE_RESOLUTIONS
              }
            >
              <span className="mr-2">
                {`${imageItem.width} x ${imageItem.height}`}
                &nbsp;
                <Translate id={words.px} />
              </span>
            </CheckPermissions>
          ) : null}
          {imageItem.dpi ? (
            <CheckPermissions
              action={LightboxWrapperPermissions.READ_LIGHTBOX_TITLE_IMAGE_DPI}
            >
              <span>
                {imageItem.dpi}
                &nbsp;
                <Translate id={words.dpi} />
              </span>
            </CheckPermissions>
          ) : null}
        </div>
      </div>
      {imagesCount > 1 && (
        <div className="flex justify-end">
          <div className="text-sm lowercase translate-x-1/2">
            {index + 1}
            &nbsp;
            <Translate id={words.of} />
            &nbsp;
            {imagesCount}
            &nbsp;
            <Translate id={words.images} />
          </div>
        </div>
      )}
    </div>
  );
}

export default LightboxWrapperBaseImageTitle;
