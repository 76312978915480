import React, { Fragment, memo, useState } from 'react';
import cl from 'classnames';
import includes from 'lodash/includes';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { BillStatus, BillStatuses } from '../../../../../../bills/billsTypes';

import { ItemMessagesListBillMessageItem } from './ItemMessagesListBillMessage.types';
import { ItemMessagesListMessageMenuDropdownMessage } from '../ItemMessagesListMessageMenuDropdown';
import { FetchMessagesCacheKey } from '../../../../../messagesTypes';

import {
  FetchBillQueryResponse,
  FETCH_BILL_QUERY
} from '../../../../../../bills/queries/fetchBill.query';

import { useBillPayment } from '../../../../../../bills/hooks/useBillPayment';

import { BillCard } from '../../../../../../bills/components/cards/BillCard';
import { PayBillModalButton } from '../../../../../../bills/components/modalButtons/PayBillModalButton';
import { ItemMessagesListBillMessageMenuDropdown } from './components/ItemMessagesListBillMessageMenuDropdown';
import { ItemMessagesListMessageIdButtons } from '../ItemMessagesListMessageIdButtons';
import { ItemMessagesListMessageSendEmailNotificationButton } from '../ItemMessagesListMessageSendEmailNotificationButton';
import { CancelBillConfirmModalButton } from '../../../../../../bills/components/buttons/CancelBillConfirmModalButton';

import { Icon } from '../../../../../../../helpers/Icon';
import { Translate } from '../../../../../../../helpers/Translate';
import { Tooltip } from '../../../../../../../helpers/tooltips/Tooltip';
import { DateTimeHelper } from '../../../../../../../helpers/DateTimeHelper';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';
import { UserLink } from '../../../../../../common/helpers/UserLink';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { BillCache } from '../../../../../../bills/BillCache';
import { MessagesPermissions } from '../../../../../messagesConstants';

import { invoicesKeys, words } from '../../../../../../../locales/keys';

interface ItemMessagesListBillMessageProps {
  message: ItemMessagesListBillMessageItem &
    ItemMessagesListMessageMenuDropdownMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  centered?: boolean;
}

function ItemMessagesListBillMessage({
  message,
  messagesCacheKey,
  centered
}: ItemMessagesListBillMessageProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);

  const {
    billPayment,
    handleBillPaymentEnabled,
    billPaymentError,
    billPaymentFetched
  } = useBillPayment<FetchBillQueryResponse>({
    uuid: message.bill?.uuid,
    query: FETCH_BILL_QUERY,
    cacheKey: BillCache.showPaymentCacheKey(message.bill?.uuid)
  });

  const nonPayableBillStatuses: BillStatus[] = [
    BillStatuses.CANCELED,
    BillStatuses.PAYING,
    BillStatuses.PAID
  ];

  const isPayable = !includes(nonPayableBillStatuses, message.bill?.status);
  const isPayoneerCheckout = message.bill?.account === 'payoneer_checkout';

  return (
    <Fragment>
      <div
        id={`message-${message.uuid}`}
        className={cl('text-xs text-gray-500 dark:text-gray-400 mt-6 mb-4', {
          'text-center': centered
        })}
        ref={setReferenceTooltipElement}
      >
        <div className={cl({ 'text-center': centered })}>
          <Icon
            className="w-4 h-4 inline-block -mt-0.5 text-current"
            icon={IconsEnum.RECEIPT_REFUND}
          />
          <span>
            <ItemMessagesListMessageIdButtons message={message} />
            {message.user ? (
              <UserLink user={message.user} text={message.user.fullName} />
            ) : null}{' '}
            <Translate id={invoicesKeys.created} />{' '}
          </span>
        </div>
      </div>
      <Tooltip
        withArrow
        placement={TooltipPlacement.TOP}
        referenceElement={referenceTooltipElement}
      >
        <DateTimeHelper date={message.createdAt} />
      </Tooltip>

      <div className="my-10 p-2">
        <div className="flex flex-col flex-1">
          <div className="w-full">
            <div className={cl('max-w-xl relative', { 'mx-auto': centered })}>
              <div
                className={cl(
                  'rounded-xl px-2 py-4 ring-8',
                  message.bill.status === BillStatuses.CANCELED
                    ? 'ring-gray-200 dark:ring-gray-800'
                    : 'ring-blue-100 dark:ring-blue-900'
                )}
              >
                <BillCard bill={message.bill} />

                {isPayable ? (
                  <Fragment>
                    {/* {isPayoneerCheckout &&
                    message.bill?.payer?.payoneerCheckoutAddress ? (
                      <PayoneerCheckoutPaymentSystems />
                    ) : null} */}

                    {!isPayoneerCheckout ? (
                      <div className="flex justify-center mt-4">
                        <div className="flex gap-1 w-72 max-w-full">
                          <PayBillModalButton
                            bill={billPayment}
                            className="py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-600 shadow-sm hover:bg-blue-500 focus:bg-blue-500 hover:shadow-md w-72 max-w-full justify-center"
                            disabled={!message.bill?.payable}
                            i18nText={words.pay}
                            icon={IconsEnum.CASH}
                            iconClassName="h-6 w-6 p-0.5"
                            onMouseEnter={handleBillPaymentEnabled}
                            billError={billPaymentError}
                            billFetched={billPaymentFetched}
                            projectNanoId={message.project?.nanoId}
                          />
                          <CheckPermissions
                            action={
                              MessagesPermissions.READ_MESSAGE_CANCEL_BILL_BUTTON
                            }
                          >
                            <CancelBillConfirmModalButton
                              disabled={!message.bill?.payable}
                              indexCacheKey={messagesCacheKey}
                              billUuid={message.bill?.uuid}
                              i18nButtonText={words.cancel}
                              className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
                            />
                          </CheckPermissions>
                        </div>
                      </div>
                    ) : null}

                    {/* {isPayoneerCheckout &&
                    !message.bill?.payer?.payoneerCheckoutAddress ? (
                      <AlertMessage
                        i18nMessage={stringsKeys.pleaseProvideYourAddress}
                      />
                    ) : null} */}

                    <ItemMessagesListMessageSendEmailNotificationButton
                      message={message}
                      messagesCacheKey={messagesCacheKey}
                      className="flex items-center justify-center mt-3"
                    />
                  </Fragment>
                ) : null}
              </div>

              <CheckPermissions
                action={MessagesPermissions.READ_MESSAGE_BILL_MENU_DROPDOWN}
              >
                <div className="absolute top-2 right-2">
                  <div className="relative">
                    {message.bill?.account === 'stripe' ||
                    message.bill?.account === 'payoneer_checkout' ? null : (
                      <ItemMessagesListBillMessageMenuDropdown
                        message={message}
                        messagesCacheKey={messagesCacheKey}
                      />
                    )}
                  </div>
                </div>
              </CheckPermissions>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default memo<ItemMessagesListBillMessageProps>(
  ItemMessagesListBillMessage
);
