import React, { Suspense } from 'react';

import { ImageEditorProps } from './ImageEditor.types';

const ImageEditor =
  typeof window === 'undefined'
    ? null
    : React.lazy(
        () =>
          import(/* webpackChunkName: "doka-image-editor" */ './ImageEditor')
      );

function ImageEditorLoader(props: ImageEditorProps) {
  if (!ImageEditor) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <ImageEditor {...props} />
    </Suspense>
  );
}

export default ImageEditorLoader;
