import startsWith from 'lodash/startsWith';
import replace from 'lodash/replace';
import fromPairs from 'lodash/fromPairs';
import keys from 'lodash/keys';
import isObject from 'lodash/isObject';

const MESSAGE_BFF_BODY_DATA_MARKER = '##bff_message_body_data:';

class MessageBffBodyData {
  static bffDataToString(data: Record<string, unknown>): string {
    const dataKeys = keys(data);

    const dataStringPart = dataKeys
      .map((key) =>
        [key, isObject(data[key]) ? JSON.stringify(data[key]) : data[key]].join(
          '='
        )
      )
      .join(';');

    const bodyString = [MESSAGE_BFF_BODY_DATA_MARKER, dataStringPart].join('');

    return bodyString;
  }

  static isBffData(messageBody: string) {
    return startsWith(messageBody, MESSAGE_BFF_BODY_DATA_MARKER);
  }
  static parseBffData<T extends Record<string, unknown>>(
    messageBody: string
  ): T | null {
    if (!MessageBffBodyData.isBffData(messageBody)) {
      return null;
    }

    const dataPart = replace(
      messageBody,
      new RegExp(`^${MESSAGE_BFF_BODY_DATA_MARKER}`),
      ''
    );

    const dataPairs = dataPart.split(';').map((stringPair) => {
      const stringPairSplit = stringPair.split('=');
      const key = stringPairSplit[0];
      const valueString = stringPairSplit[1];
      let value = undefined;
      try {
        value = JSON.parse(valueString);
      } catch {
        value = valueString;
      }

      return [key, value];
    });

    const data = fromPairs(dataPairs) as T;

    return data;
  }
}

export default MessageBffBodyData;
