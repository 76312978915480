import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';

import { BillCache } from '../../../../../BillCache';

import { BillUUID, CheckBillIndexCacheKey } from '../../../../../billsTypes';

import { useCheckBill } from '../../../../../hooks/useCheckBill';

import {
  CheckBillQueryResponse,
  CHECK_BILL_QUERY
} from '../../../../../queries/checkBill.query';

interface CheckBillButtonOptions {
  indexCacheKey: CheckBillIndexCacheKey;
  uuid: BillUUID;
}

function useCheckBillButton({ indexCacheKey, uuid }: CheckBillButtonOptions) {
  const { checkBill, checkBillLoading, checkBillErrorMessage } =
    useCheckBill<CheckBillQueryResponse>({
      query: CHECK_BILL_QUERY,
      indexCacheKey,
      showCacheKey: BillCache.showCacheKey(uuid)
    });

  const handleCheckBill = () =>
    checkBill({ uuid }).catch((reason) =>
      console.log('Error reason: ', reason)
    );

  useShowToastOnErrorChange({ error: checkBillErrorMessage });

  return {
    handleCheckBill,
    checkBillLoading,
    checkBillErrorMessage
  };
}

export default useCheckBillButton;
