import {
  BillGqlError,
  BillUUID,
  CancelBillGqlQuery,
  CancelBillGqlStatus,
  CancelBillIndexCacheKey,
  CancelBillShowCacheKey
} from '../../billsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { BillCache } from '../../BillCache';

interface CancelBillOptions {
  query: CancelBillGqlQuery;
  indexCacheKey?: CancelBillIndexCacheKey;
  showCacheKey?: CancelBillShowCacheKey;
}

export interface CancelBillResponse<CancelBillRecordType> {
  cancelBill: {
    status: CancelBillGqlStatus;
    recordUuid: BillUUID;
    record: CancelBillRecordType;
    errors: CancelBillError;
  };
}

export interface CancelBillInput {
  uuid: BillUUID;
}

export interface CancelBillError {
  fullMessages: BillGqlError;
}

const action = 'cancelBill';
const pluralScope = BillCache.indexCacheKey();

function useCancelBill<CancelBillRecordType>({
  query,
  indexCacheKey = pluralScope,
  showCacheKey
}: CancelBillOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    CancelBillInput,
    CancelBillResponse<CancelBillRecordType>,
    CancelBillError,
    CancelBillRecordType
  >({ action, pluralScope: indexCacheKey, scope: showCacheKey, query });

  return {
    cancelBillData: updateQueryData,
    cancelBillError: updateQueryError,
    cancelBillLoading: updateQueryLoading,
    cancelBillErrorMessage: updateQueryErrorMessage,
    cancelBill: updateQuery,
    cancelBillReset: updateQueryReset
  };
}

export default useCancelBill;
