import { ClassName } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import { MessagesListMessageImageProductButtons } from './components/MessagesListMessageImageProductButtons';
import {
  MessagesListMessageImageHandleOpenLightbox,
  MessagesListMessageImageSelectedProductAttachment
} from '../MessagesListMessageImage';

import {
  ImageHelper,
  FileAttachmentItemImageVersions
} from '../../../../../../../helpers/ImageHelper';
import { LinkHelper } from '../../../../../../../helpers/links/LinkHelper';

import { Icon } from '../../../../../../../helpers/Icon';

interface MessagesListMessageImageProductProps {
  linkClassName: ClassName;
  imageClassName: ClassName;
  attachment: MessagesListMessageImageSelectedProductAttachment;
  handleOpenLightbox: MessagesListMessageImageHandleOpenLightbox;
  tooltipSingleton?: boolean;
}

function MessagesListMessageImageProduct({
  linkClassName,
  imageClassName,
  attachment,
  handleOpenLightbox,
  tooltipSingleton
}: MessagesListMessageImageProductProps) {
  return (
    <div className={linkClassName}>
      <MessagesListMessageImageProductButtons attachment={attachment} />
      <LinkHelper
        href={attachment.product.image.file}
        target="_blank"
        onClick={handleOpenLightbox}
      >
        <ImageHelper
          className={imageClassName}
          src={attachment.product.image.file}
          alt={`${attachment.product.brand?.localizedName} ${attachment.product.name}`}
          version={FileAttachmentItemImageVersions.BigThumb538x435}
          tooltipSingleton={tooltipSingleton}
          tooltipAlt
          tooltipAltInteractive
          tooltipAltAppendToBody
        />
        <div className="absolute bottom-0 left-0 p-1 bg-indigo-600 text-white flex rounded-bl rounded-tr">
          <Icon icon={IconsEnum.CUBE_OUTLINE} />
        </div>
      </LinkHelper>
    </div>
  );
}

export default MessagesListMessageImageProduct;
