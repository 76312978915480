import {
  FetchItemsFilters,
  FetchItemsSort,
  FetchItemsPage,
  FetchItemsLimit,
  FetchItemsGqlQuery
} from '../../../../../../../types';

import { generateUuid } from '../../../../../../../utils/generateUuid';
import { getQueryName } from '../../../../../../../utils/getQueryName';
import { productionConsole } from '../../../../../../../utils/productionConsole';

import { graphQLClient } from '../../graphQLClients';

interface FetchItemsProps {
  query: FetchItemsGqlQuery;
  filters: FetchItemsFilters;
  sort: FetchItemsSort;
  page: FetchItemsPage;
  limit: FetchItemsLimit;
}

export function fetchItems({
  query,
  filters,
  sort,
  page,
  limit
}: FetchItemsProps) {
  const { rawName, name } = getQueryName(query);

  productionConsole(
    'log',
    'fetchItems',
    { filters, sort, page, limit },
    { rawName, name },
    JSON.stringify(filters)
  );

  const requestId = generateUuid();

  graphQLClient.addQueryParams(name, requestId);

  return graphQLClient.request(
    query,
    {
      filters,
      sort,
      limit,
      offset: (page - 1) * limit
    },
    { requestId }
  );
}
