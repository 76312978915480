import filter from 'lodash/filter';
import includes from 'lodash/includes';

import { I18nText, ID, UUID } from '../../../../../../types';
import { UsersTabsType } from '../../../../../users/usersTypes';

import { useInviteUsersModalBody } from './hooks/useInviteUsersModalBody';

import {
  ItemIndexSidebarTeamItemUser,
  ItemIndexSidebarTeamItemUsers
} from '../../../../components/sidebars/ItemIndexSidebar/components/ItemIndexSidebarTeam';
import { SelectedUsersList } from './components/SelectedUsersList';
import { InviteWorkersTab } from './components/InviteWorkersTab';
import { InviteClientsTab } from './components/InviteClientsTab';

import { AlertMessage } from '../../../../../../helpers/AlertMessage';
import { CheckPermissions } from '../../../../../../helpers/CheckPermissions';
import { TabsHelper } from '../../../../../../helpers/TabsHelper';
import { TabsHelperDataType } from '../../../../../../helpers/TabsHelper/TabsHelper';
import { TextFilter } from '../../../../../../helpers/filters/TextFilter';

import { words } from '../../../../../../locales/keys';
import { CommonPermissions } from '../../../../commonConstants';

const tabs: TabsHelperDataType[] = [
  {
    value: 'workers',
    i18nText: words.artists,
    icon: null,
    tooltipI18nText: words.artists
  },
  {
    value: 'clients',
    i18nText: words.clients,
    icon: null,
    tooltipI18nText: words.clients
  }
];

interface InviteUsersModalBodyProps {
  availableTabs?: UsersTabsType;
  errorMessage?: string | null;
  invitedUserUuids?: UUID[];
  selectedUsers: ItemIndexSidebarTeamItemUsers;
  selectedUserIds: ID[];
  onSelectUser: (user: ItemIndexSidebarTeamItemUser) => void;
  onDeselectUser: (user: ItemIndexSidebarTeamItemUser) => void;
  alreadySelectedI18nTitle?: I18nText;
  withoutCheckAll?: boolean;
}

function InviteUsersModalBody({
  availableTabs = ['workers', 'clients'],
  errorMessage,
  invitedUserUuids,
  selectedUsers,
  selectedUserIds,
  onSelectUser,
  onDeselectUser,
  alreadySelectedI18nTitle,
  withoutCheckAll
}: InviteUsersModalBodyProps) {
  const {
    fullName,
    groupIds,
    handleChangeFullName,
    handleChangeGroupIdsFilter,
    handleChangeTeamIdsFilter,
    handleCheckUser,
    handleCheckAllUsers,
    handleTabChange,
    openedTab,
    teamIds
  } = useInviteUsersModalBody({
    availableTabs,
    onDeselectUser,
    onSelectUser
  });

  return (
    <div className="space-y-6 mt-2">
      <TabsHelper
        data={filter(tabs, (tab) => includes(availableTabs, tab.value))}
        defaultTab={openedTab}
        onChange={handleTabChange}
      />

      <div>
        <div>
          <CheckPermissions
            action={
              openedTab === 'workers'
                ? CommonPermissions.READ_WORKERS_FULL_NAME_FILTER
                : CommonPermissions.READ_CLIENTS_FULL_NAME_FILTER
            }
          >
            <div className="flex items-center w-full">
              <TextFilter
                id="search-user-by-name"
                className="bg-gray-100 dark:bg-gray-800 focus-within:border-gray-300 dark:focus-within:border-gray-600 focus-within:shadow-xl dark:focus-within:bg-gray-700 focus-within:bg-white dark:focus-within:text-gray-300 focus-within:text-gray-600 h-10 relative rounded-lg text-gray-400 border border-transparent w-full"
                inputClassName="bg-transparent block border-transparent dark:focus:placeholder-gray-500 dark:text-gray-200 focus:outline-none focus:ring-4 focus:ring-blue-300 focus:placeholder-gray-300 rounded-lg h-full pl-12 placeholder-gray-400 pr-4 py-2 text-gray-900 w-full"
                i18nPlaceholder="words.search_user_by_name"
                name="fullName"
                onChange={handleChangeFullName}
                value={fullName}
              />
            </div>
          </CheckPermissions>

          {openedTab === 'workers' ? (
            <InviteWorkersTab
              fullName={fullName}
              groupIds={groupIds}
              onChangeGroupIdsFilter={handleChangeGroupIdsFilter}
              onCheckUser={handleCheckUser}
              onCheckAllUsers={handleCheckAllUsers}
              invitedUserUuids={invitedUserUuids}
              selectedUserIds={selectedUserIds}
              alreadySelectedI18nTitle={alreadySelectedI18nTitle}
              withoutCheckAll={withoutCheckAll}
            />
          ) : (
            <InviteClientsTab
              fullName={fullName}
              onChangeTeamIdsFilter={handleChangeTeamIdsFilter}
              onCheckUser={handleCheckUser}
              onCheckAllUsers={handleCheckAllUsers}
              invitedUserUuids={invitedUserUuids}
              selectedUserIds={selectedUserIds}
              teamIds={teamIds}
              alreadySelectedI18nTitle={alreadySelectedI18nTitle}
              withoutCheckAll={withoutCheckAll}
            />
          )}

          <SelectedUsersList
            onDeselectUser={onDeselectUser}
            selectedUsers={selectedUsers}
          />

          <AlertMessage message={errorMessage} />
        </div>
      </div>
    </div>
  );
}

export default InviteUsersModalBody;
