import React, { useState, useMemo } from 'react';
import first from 'lodash/first';
import sortBy from 'lodash/sortBy';

import { ID } from '../../../../../../../../../types';

import {
  ItemMessagesListAiDetectSearchMessageImageMessage,
  ItemMessagesListAiDetectSearchMessageImageDetectedItem
} from './ItemMessagesListAiDetectSearchMessageImage.types';

import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';
import { useMountEffect } from '../../../../../../../../../common/hooks/useMountEffect';

import { ItemMessagesListAiDetectSearchMessageImageDetects } from '../ItemMessagesListAiDetectSearchMessageImageDetects';

import {
  ImageHelper,
  FileAttachmentItemImageVersions
} from '../../../../../../../../../helpers/ImageHelper';

import { getImageUrlDimensions } from '../../../../../../../../../utils/getImageUrlDimensions';

import { MessagesPermissions } from '../../../../../../../messagesConstants';

function getPercentagePosition(
  size: number,
  part: number,
  options: { reverse?: boolean } = {}
) {
  const partPercentage = part / (size / 100);
  const percentage = options.reverse ? 100 - partPercentage : partPercentage;
  return percentage;
}

interface ItemMessagesListAiDetectSearchMessageImageProps {
  detectedItems: ItemMessagesListAiDetectSearchMessageImageDetectedItem[];
  message: ItemMessagesListAiDetectSearchMessageImageMessage;
  selectedBoundingId: ID;
  onSelectChange: (id: ID) => void;
}
function ItemMessagesListAiDetectSearchMessageImage({
  detectedItems,
  message,
  selectedBoundingId,
  onSelectChange
}: ItemMessagesListAiDetectSearchMessageImageProps) {
  const currentUser = useCurrentUser();

  const fileAttachment = first(message?.fileAttachments);

  const [imageDimensions, setImageDimensions] = useState<{
    width?: number;
    height?: number;
  }>({ width: fileAttachment?.width, height: fileAttachment?.height });

  const zIndexesById = useMemo<Record<ID, number>>(() => {
    const result = {};

    const sortedFromBigToSmall = sortBy(detectedItems, ({ x1, x2, y1, y2 }) => {
      const width = x2 - x1;
      const height = y2 - y1;
      return -(width * height);
    });

    sortedFromBigToSmall.forEach((detectedItem, index) => {
      result[detectedItem.id] = index;
    });

    return result;
  }, [detectedItems]);

  useMountEffect(() => {
    if (
      (imageDimensions?.width && imageDimensions?.height) ||
      !fileAttachment?.file
    ) {
      return;
    }
    getImageUrlDimensions(fileAttachment.file).then((dimensions) =>
      setImageDimensions(dimensions)
    );
  });

  if (!fileAttachment) {
    return null;
  }

  return (
    <div className="flex bg-gray-50 dark:bg-gray-800 ">
      <div className="m-auto relative overflow-hidden">
        <ImageHelper
          className="object-cover bg-gray-100 dark:bg-gray-850"
          src={fileAttachment.file}
          alt={fileAttachment.name}
          version={FileAttachmentItemImageVersions.FullScreenThumb1000x850}
          tooltipAlt
          tooltipAltInteractive
          tooltipAltAppendToBody
        />
        {imageDimensions?.width &&
          imageDimensions?.height &&
          detectedItems.map((item) => (
            <ItemMessagesListAiDetectSearchMessageImageDetects
              key={item.id}
              title={
                currentUser.hasPermissions(
                  MessagesPermissions.READ_MESSAGE_AI_SEARCH_DETECTED_ITEM_TITLE
                )
                  ? JSON.stringify(item, null, 2)
                  : undefined
              }
              clickable
              id={item.id}
              zIndex={zIndexesById[item.id]}
              active={selectedBoundingId === item.id}
              percentageX1={getPercentagePosition(
                imageDimensions.width,
                item.x1
              )}
              percentageX2={getPercentagePosition(
                imageDimensions.width,
                item.x2,
                {
                  reverse: true
                }
              )}
              percentageY1={getPercentagePosition(
                imageDimensions.height,
                item.y1
              )}
              percentageY2={getPercentagePosition(
                imageDimensions.height,
                item.y2,
                { reverse: true }
              )}
              onSelectChange={onSelectChange}
            />
          ))}
      </div>
    </div>
  );
}

export default ItemMessagesListAiDetectSearchMessageImage;
