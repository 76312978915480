import React, { Fragment } from 'react';

import {
  FetchProductsCacheKeys,
  UpdateProductCacheAction
} from '../../../../../productsTypes';

import { ProductsListItemLightboxDownloadButton } from '../../../ProductsList/components/ProductsListItemLightboxDownloadButton';
import { ProductsListItemLightboxFavoriteButton } from '../../../ProductsList/components/ProductsListItemLightboxFavoriteButton';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { ProductsBlockListItemLightboxBottomButtonsProduct } from './ProductsBlockListItemLightboxBottomButtons.types';

import { ProductsPermissions } from '../../../../../productsConstants';

interface ProductsBlockListItemLightboxBottomButtonsProps {
  product: ProductsBlockListItemLightboxBottomButtonsProduct;
  productsCacheKeys?: FetchProductsCacheKeys;
  updateProductCache: UpdateProductCacheAction<ProductsBlockListItemLightboxBottomButtonsProduct>;
}

function ProductsBlockListItemLightboxBottomButtons({
  product,
  productsCacheKeys,
  updateProductCache
}: ProductsBlockListItemLightboxBottomButtonsProps) {
  return (
    <Fragment>
      <ProductsListItemLightboxFavoriteButton
        product={product}
        productsCacheKeys={productsCacheKeys}
        updateProductCache={updateProductCache}
      />
      <CheckPermissions
        action={ProductsPermissions.READ_PRODUCT_DOWNLOAD_BUTTON}
      >
        <ProductsListItemLightboxDownloadButton product={product} />
      </CheckPermissions>
    </Fragment>
  );
}

export default ProductsBlockListItemLightboxBottomButtons;
