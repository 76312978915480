import { ClientError } from 'graphql-request';

function retryCustomFunction(
  failureCount: number,
  error?: ClientError | Error
) {
  {
    return !(error?.message === 'Network request failed' || failureCount >= 3);
  }
}

export default retryCustomFunction;
