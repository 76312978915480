import React, { useCallback } from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { HexColor } from '../../../../../../../types';

import { copyToClipboard } from '../../../../../../../utils/copyToClipboard';
import { isBlackText } from '../../../../../../../utils/isBlackText';
import { TooltipButtonHelper } from '../../../../../../../helpers/buttons/TooltipButtonHelper';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';
import { Icon } from '../../../../../../../helpers/Icon';

import { words } from '../../../../../../../locales/keys';

interface MessagesListMessageCopyColorButtonProps {
  color: HexColor;
}

function MessagesListMessageCopyColorButton({
  color
}: MessagesListMessageCopyColorButtonProps) {
  const handleCopy = useCallback(() => copyToClipboard(color), [color]);

  const isBlackTextButton = isBlackText(color);

  return (
    <div className="h-16 relative rounded bg-gray-100 dark:bg-gray-850 group group w-16">
      <TooltipButtonHelper
        className={cl(
          'w-full h-full rounded',
          isBlackTextButton ? 'text-black' : 'text-white'
        )}
        tooltipPlacement={TooltipPlacement.TOP}
        tooltipI18nText={words.clickToCopy}
        style={{ background: color }}
        onClick={handleCopy}
      >
        <div className="absolute bottom-0 left-0 bg-gray-600 text-white flex rounded-bl rounded-tr">
          <Icon icon={IconsEnum.COLOR_SWATCH_OUTLINE} />
        </div>
      </TooltipButtonHelper>
    </div>
  );
}

export default MessagesListMessageCopyColorButton;
