import { useMutation, useQueryClient } from 'react-query';
import {
  deleteInviteUserSearch,
  DeleteInviteUserSearchError,
  DeleteInviteUserSearchInput,
  DeleteInviteUserSearchResponse
} from '../../actions/deleteInviteUserSearch';

interface DeleteInviteUserSearchOptions {
  query: string;
  inviteUserSearchesCacheKey?: string;
}

function useDeleteInviteUserSearch({
  query,
  inviteUserSearchesCacheKey
}: DeleteInviteUserSearchOptions) {
  const queryClient = useQueryClient();

  const { data, error, isLoading, mutateAsync, reset } = useMutation<
    DeleteInviteUserSearchResponse,
    DeleteInviteUserSearchError,
    DeleteInviteUserSearchInput
  >((input) => deleteInviteUserSearch({ query, input }), {
    onSuccess: () => {
      inviteUserSearchesCacheKey &&
        queryClient.invalidateQueries(inviteUserSearchesCacheKey);
    }
  });

  return {
    deleteInviteUserSearchData: data,
    deleteInviteUserSearchError: error,
    deleteInviteUserSearchLoading: isLoading,
    deleteInviteUserSearchErrorMessage: error?.fullMessages?.join(', '),
    deleteInviteUserSearch: mutateAsync,
    deleteInviteUserSearchReset: reset
  };
}

export default useDeleteInviteUserSearch;
