import React from 'react';

import { IconsEnum } from '../../../../assets/icons/types';

import { PureTooltipIconButtonHelper } from '../../../buttons/PureTooltipIconButtonHelper';

import { words } from '../../../../locales/keys';

export interface LightboxWrapperImageEditButtonProps {
  onImageEdit: () => void;
}

function LightboxWrapperImageEditButton({
  onImageEdit
}: LightboxWrapperImageEditButtonProps) {
  return (
    <PureTooltipIconButtonHelper
      className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-white focus:ring-offset-0 hover:bg-white hover:bg-opacity-10 text-gray-300 hover:text-white"
      icon={IconsEnum.PENCIL}
      iconClassName="h-6 w-6"
      tooltipI18nText={words.edit}
      tooltipSingleton
      onClick={onImageEdit}
    />
  );
}

export default LightboxWrapperImageEditButton;
