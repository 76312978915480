import React from 'react';

import { FileUrl } from '../../../../types';

import { IconsEnum } from '../../../../assets/icons/types';

import { PureTooltipIconLinkHelper } from '../../../links/PureTooltipIconLinkHelper';

export interface LightboxWrapperOpenInNewTabButtonProps {
  file: FileUrl;
}

function LightboxWrapperOpenInNewTabButton({
  file
}: LightboxWrapperOpenInNewTabButtonProps) {
  return (
    <PureTooltipIconLinkHelper
      href={file}
      className="focus:outline-none focus:ring-2 py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-white focus:ring-offset-0 hover:bg-white hover:bg-opacity-10 text-gray-300 hover:text-white"
      target="_blank"
      rel="noreferrer"
      iconClassName="h-6 w-6 transform rotate-45"
      icon={IconsEnum.ARROW_NARROW_UP_OUTLINE}
      tooltipI18nText="words.open_in_new_tab"
      tooltipSingleton
    />
  );
}

export default LightboxWrapperOpenInNewTabButton;
