import { useEffect } from 'react';

import { UsersTabType } from '../../../../../../../../../users/usersTypes';

import { GET_INVITE_USER_SEARCHES_QUERY } from '../../../../../../../../../inviteUserSearches/queries/inviteUserSearches.query';
import { DELETE_INVITE_USER_SEARCH_QUERY } from '../../../../../../../../../inviteUserSearches/queries/deleteInviteUserSearch.query';

import { useCurrentUser } from '../../../../../../../../../../auth/hooks/useAuth';
import { useInviteUserSearches } from '../../../../../../../../../inviteUserSearches/hooks/useInviteUserSearches';
import { useDeleteInviteUserSearch } from '../../../../../../../../../inviteUserSearches/hooks/useDeleteInviteUserSearch';
import { usePreviousValue } from '../../../../../../../../../../common/hooks/usePreviousValue';

import { UserCache } from '../../../../../../../../../users/UserCache';

interface RecentlySelectedUsersListOptions {
  tab: UsersTabType;
}

function useRecentlySelectedUsersList({
  tab
}: RecentlySelectedUsersListOptions) {
  const currentUser = useCurrentUser();

  const {
    inviteUserSearches,
    inviteUserSearchesError,
    inviteUserSearchesFetched,
    filterInviteUserSearches
  } = useInviteUserSearches({
    cacheKey: UserCache.recentlySelectedUsersCacheKey(tab),
    query: GET_INVITE_USER_SEARCHES_QUERY,
    initialFilters: {
      userId: { eq: currentUser.id },
      userType: { ilike: tab }
    },
    initialLimit: 18
  });

  const {
    deleteInviteUserSearch,
    deleteInviteUserSearchErrorMessage,
    deleteInviteUserSearchLoading
  } = useDeleteInviteUserSearch({
    query: DELETE_INVITE_USER_SEARCH_QUERY,
    inviteUserSearchesCacheKey: UserCache.recentlySelectedUsersCacheKey(tab)
  });

  const prevTab = usePreviousValue(tab);

  useEffect(() => {
    if (prevTab !== tab) {
      filterInviteUserSearches({
        userId: { eq: currentUser.id },
        userType: { ilike: tab }
      });
    }
  }, [tab, prevTab, filterInviteUserSearches, currentUser.id]);

  return {
    inviteUserSearches,
    inviteUserSearchesError,
    inviteUserSearchesFetched,
    deleteInviteUserSearch,
    deleteInviteUserSearchErrorMessage,
    deleteInviteUserSearchLoading
  };
}

export default useRecentlySelectedUsersList;
