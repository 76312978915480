import set from 'lodash/set';

import { InfiniteIndexQueryData } from '../../../../useInfiniteIndexQuery.types';

interface GetUpdatedQueryDataOptions<T> {
  addItem: T;
  previousQueryData: InfiniteIndexQueryData<T>;
  scope: string;
  atStart?: boolean;
}

function getUpdatedQueryData<T>({
  addItem,
  previousQueryData,
  scope,
  atStart
}: GetUpdatedQueryDataOptions<T>): InfiniteIndexQueryData<T> | null {
  const pageIndex = atStart ? 0 : previousQueryData.pages.length - 1;

  const previousPage = previousQueryData.pages[pageIndex];

  if (!previousPage) {
    return null;
  }

  const updatedPage = {
    ...previousPage,
    [scope]: {
      ...previousPage[scope],
      nodes: atStart
        ? [addItem, ...previousPage[scope].nodes]
        : [...previousPage[scope].nodes, addItem]
    }
  };

  return {
    ...previousQueryData,
    pages: set([...previousQueryData.pages], pageIndex, updatedPage)
  };
}

export default getUpdatedQueryData;
