import { ID } from '../../../../../types';

import { CategoriesFilter } from '../../../../categories/components/filters/CategoriesFilter';

import { FiltersProps } from '../../../../../helpers/filters/hooks/useFilters';

const userGroupsScope = 'users_groups';

interface UserGroupsFilterProps extends FiltersProps<ID[]> {
  className?: string;
}

function UserGroupsFilter({
  className,
  name,
  onChange,
  value
}: UserGroupsFilterProps) {
  return (
    <div className={className}>
      <CategoriesFilter
        i18nPlaceholder="models.categories.groups.select..."
        name={name}
        onChange={onChange}
        scope={userGroupsScope}
        value={value}
      />
    </div>
  );
}

export default UserGroupsFilter;
