import React, { ReactNode } from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../../../assets/icons/types';
import {
  ClassName,
  I18nText,
  PositionStyleEnum,
  TableViewType
} from '../../../../../types';

import { DropdownHelper } from '../../../../../helpers/dropdowns/DropdownHelper';
import { PopoverPlacement } from '../../../../../helpers/Popover/popoverConstants';

import { IndexTableCell } from '../IndexTableCell';

interface IndexTableCellDropdownProps {
  children: ReactNode;
  i18nText?: I18nText;
  buttonClassName?: ClassName;
  icon?: IconsEnum;
  disabled?: boolean;
  viewType?: TableViewType;
  tabIndex?: number;
  tdClassName?: ClassName;
  dropdownPlacement?: PopoverPlacement;
}

function IndexTableCellDropdown({
  children,
  i18nText,
  buttonClassName,
  disabled,
  viewType,
  icon,
  tabIndex,
  tdClassName,
  dropdownPlacement = PopoverPlacement.BOTTOM_END
}: IndexTableCellDropdownProps) {
  return (
    <IndexTableCell
      tdClassName={
        tdClassName ||
        cl(
          'sticky focus-within:z-5 bg-white bg-opacity-90 dark:bg-gray-900 dark:bg-opacity-90 text-center group-hover:bg-gray-100 dark:group-hover:bg-gray-850 group-hover:bg-opacity-80 dark:group-hover:bg-opacity-90 right-0',
          {
            'pr-3 pl-1 py-2': !viewType || viewType === TableViewType.DEFAULT,
            'px-3 py-0.5 text-xs': viewType === TableViewType.SLIM
          }
        )
      }
      tabIndex={tabIndex}
    >
      <DropdownHelper
        className="relative flex items-center justify-center"
        buttonClassName={
          buttonClassName ||
          cl(
            'rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0',
            {
              'py-1.5 pl-1.5 pr-1.5':
                !viewType || viewType === TableViewType.DEFAULT,
              '0.5 pl-0.5 pr-0.5': viewType === TableViewType.SLIM
            }
          )
        }
        dropdownPlacement={dropdownPlacement}
        popoverClassName="relative min-w-48 z-10 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700"
        i18nText={i18nText}
        icon={icon}
        disabled={disabled}
        popoverPositionStyle={PositionStyleEnum.fixed}
      >
        {children}
      </DropdownHelper>
    </IndexTableCell>
  );
}

export default IndexTableCellDropdown;
