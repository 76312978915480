import isEmpty from 'lodash/isEmpty';

import { MessagesListMessageColorsMessage } from './MessagesListMessageColors.types';

import { MessagesListMessageCopyColorButton } from '../../buttons/MessagesListMessageCopyColorButton';

interface MessagesListMessageColorsProps {
  message: MessagesListMessageColorsMessage;
}

function MessagesListMessageColors({
  message
}: MessagesListMessageColorsProps) {
  if (isEmpty(message.colors)) {
    return null;
  }

  return (
    <>
      {message.colors.map((color) => (
        <MessagesListMessageCopyColorButton
          key={`color-${color}`}
          color={color}
        />
      ))}
    </>
  );
}

export default MessagesListMessageColors;
