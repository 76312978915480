import React, { useMemo } from 'react';

import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';

import { ItemMessagesListAiSearchMessageBodyMessage } from './ItemMessagesListAiSearchMessageBody.types';

import { MatchResultUUID } from '../../../../../../../../matchResults/matchResultsTypes';

import { ProductsMatchResultBlock } from '../../../../../../../../products/components/containers/ProductsMatchResultBlock';

import { Translate } from '../../../../../../../../../helpers/Translate';

import { aiSearchKeys } from '../../../../../../../../../locales/keys';

import { ProductCache } from '../../../../../../../../products/ProductCache';

const initialFilters = {
  nda: false
};

interface ItemMessagesListAiSearchMessageBodyProps {
  matchResultUuid: MatchResultUUID;
  message: ItemMessagesListAiSearchMessageBodyMessage;
  messagesCacheKey: FetchMessagesCacheKey;
}
function ItemMessagesListAiSearchMessageBody({
  matchResultUuid,
  message,
  messagesCacheKey
}: ItemMessagesListAiSearchMessageBodyProps) {
  const taskId = message?.taskId;
  const projectId = message?.projectId;

  const messagesCacheKeys = useMemo<FetchMessagesCacheKey[]>(
    () => [messagesCacheKey],
    [messagesCacheKey]
  );

  if (!matchResultUuid) {
    return null;
  }
  return (
    <div className="p-2 sm:px-4 sm:pb-4 bg-white dark:bg-gray-850 rounded-b-lg border-t dark:border-gray-700">
      <div className="mb-2 flex justify-between flex-col md:flex-row gap-2 items-center">
        <div className="text-md">
          <Translate id={aiSearchKeys.objectsFoundInTheSourceImage} />
        </div>
        <div className="flex gap-0.5">{/* buttons */}</div>
      </div>

      <div>
        <ProductsMatchResultBlock
          productsCacheKey={ProductCache.matchResultProductsCacheKey(
            matchResultUuid
          )}
          taskId={taskId}
          projectId={taskId ? undefined : projectId}
          messagesCacheKeys={messagesCacheKeys}
          initialFilters={initialFilters}
          initialLimit={30}
          matchResultUuid={matchResultUuid}
        />
      </div>
    </div>
  );
}

export default ItemMessagesListAiSearchMessageBody;
