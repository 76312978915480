import { FetchItemCacheKey, FetchItemsGqlQuery } from '../../../../types';
import {
  FetchTeamsGqlQuery,
  FetchTeamsCacheKey,
  FetchTeamsFilters,
  FetchTeamsSort,
  FetchTeamsPage,
  FetchTeamsLimit
} from '../../teamsTypes';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

import {
  INITIAL_TEAMS_FILTERS,
  INITIAL_TEAMS_LIMIT,
  INITIAL_TEAMS_PAGE,
  INITIAL_TEAMS_SORT
} from '../../teamsConstants';

interface TeamsDefaultOptions {
  cacheKey: FetchTeamsCacheKey;
  query: FetchTeamsGqlQuery;
  initialFilters?: FetchTeamsFilters;
  initialSort?: FetchTeamsSort;
  initialPage?: FetchTeamsPage;
  initialLimit?: FetchTeamsLimit;
  options?: {
    cacheTime?: number;
    staleTime?: number;
    keepPreviousData?: boolean;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

interface TeamsWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemsGqlQuery;
}

interface TeamsWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

type TeamsOptions = TeamsDefaultOptions &
  (TeamsWithPrefetchItemOptions | TeamsWithoutPrefetchItemOptions);

const scope = 'teams';

function usePaginatedTeams<ItemType>({
  cacheKey,
  query,
  initialFilters = INITIAL_TEAMS_FILTERS,
  initialSort = INITIAL_TEAMS_SORT,
  initialPage = INITIAL_TEAMS_PAGE,
  initialLimit = INITIAL_TEAMS_LIMIT,
  fetchItemCacheKey,
  fetchItemQuery,
  options = {}
}: TeamsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    changeItemsFilters,
    clearItemsFilters,
    filterItems,
    clearItemsFiltersPersistInitial,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems,
    updateItemCache,
    prefetchItem
  } = useFinIndexQuery<ItemType>({
    scope,
    query,
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    fetchItemCacheKey,
    fetchItemQuery,
    options
  });

  return {
    teamsData: data,
    teams: items,
    teamsError: itemsError,
    teamsTotalCount: itemsTotalCount,
    teamsFetched: isFetched,
    teamsLoading: isLoading,
    teamsIsPlaceholderData: isPlaceholderData,
    teamsFilters: currentFilters,
    teamsSort: currentSort,
    teamsPage: currentPage,
    teamsLimit: currentLimit,
    filterTeams: filterItems,
    clearTeamsFiltersPersistInitial: clearItemsFiltersPersistInitial,
    changeTeamsFilters: changeItemsFilters,
    clearTeamsFilters: clearItemsFilters,
    sortTeams: sortItems,
    paginateTeams: paginateItems,
    limitTeams: limitItems,
    prefetchTeams: prefetchItems,
    prefetchTeam: prefetchItem,
    updateTeamCache: updateItemCache
  };
}

export default usePaginatedTeams;
