import { gql } from 'graphql-request';

import { TaskUUID, TaskName, TaskID, TaskNanoID } from '../tasksTypes';

export interface FetchSelectFieldTasksQueryResponse {
  id: TaskID;
  uuid: TaskUUID;
  nanoId: TaskNanoID;
  name: TaskName;
}

export const FETCH_SELECT_FIELD_TASKS_QUERY = gql`
  query SelectFieldTasks(
    $filters: TasksFilters
    $sort: [TasksSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    tasks(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        uuid
        nanoId
        name
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
