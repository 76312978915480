import set from 'lodash/set';

import { InfiniteIndexQueryData } from '../../../../useInfiniteIndexQuery.types';

interface GetUpdatedQueryDataOptions<T> {
  updateFunction: (item: T) => Partial<T>;
  previousQueryData: InfiniteIndexQueryData<T>;
  scope: string;
  pageIndex: number;
  itemIndex: number;
}

function getUpdatedQueryData<T>({
  updateFunction,
  previousQueryData,
  scope,
  pageIndex,
  itemIndex
}: GetUpdatedQueryDataOptions<T>): InfiniteIndexQueryData<T> | null {
  const previousPage = previousQueryData.pages[pageIndex];

  const previousItem = previousPage[scope].nodes[itemIndex];

  if (!previousPage || !previousItem) {
    return null;
  }

  const updateData = updateFunction ? updateFunction(previousItem) : {};

  const updatedItem = { ...previousItem, ...updateData };

  const updatedPage = {
    ...previousPage,
    [scope]: {
      ...previousPage[scope],
      nodes: set([...previousPage[scope].nodes], itemIndex, updatedItem)
    }
  };

  return {
    ...previousQueryData,
    pages: set([...previousQueryData.pages], pageIndex, updatedPage)
  };
}

export default getUpdatedQueryData;
