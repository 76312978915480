import React, { memo, useCallback } from 'react';

import { OnLightboxOpenAction } from '../../../../../helpers/LightboxWrapper';

import { TaskID } from '../../../../tasks/tasksTypes';
import { ProjectID } from '../../../../projects/projectsTypes';
import { FetchMessagesCacheKey } from '../../../../messages/messagesTypes';

import {
  FetchProductsCacheKeys,
  UpdateProductCacheAction,
  ProductNanoID
} from '../../../productsTypes';
import { ProductsBlockListItemProduct } from './ProductsBlockListItem.types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { ProductsCopyLinkButton } from '../../buttons/ProductsCopyLinkButton';
import { DownloadProductButton } from '../../buttons/DownloadProductButton';
import { ProductsListItemEditLink } from '../ProductsListItem/components/ProductsListItemEditLink';
import { ProductsListItemNda } from '../ProductsListItem/components/ProductsListItemNda';
import { ProductsListItemOpenLightboxButton } from '../ProductsListItem/components/ProductsListItemOpenLightboxButton';
import { ProductsListItemPreview } from '../ProductsListItem/components/ProductsListItemPreview';
import { SubmitProductToProjectOrTaskModalButton } from '../../buttons/SubmitProductToProjectOrTaskModalButton';
import { ProductsListItemSku } from '../ProductsListItem/components/ProductsListItemSku';
import { CreateMessageFromAiProductsSearchByAngleButton } from '../../../../messages/components/buttons/CreateMessageFromAiProductsSearchByAngleButton';
import { ProductFavoriteButton } from '../../buttons/ProductFavoriteButton';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { TextWithTooltip } from '../../../../../helpers/TextWithTooltip';
import { NextPureTooltipIconLinkHelper } from '../../../../../helpers/links/NextPureTooltipIconLinkHelper';
import { TooltipSingletonSourceWrapper } from '../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { ProductsPermissions } from '../../../productsConstants';

import { words, aiSearchKeys } from '../../../../../locales/keys';
import { ProductPath } from '../../../ProductPath';

interface ProductsBlockListItemProps {
  product: ProductsBlockListItemProduct;
  productsCacheKeys?: FetchProductsCacheKeys;
  projectId?: ProjectID;
  taskId?: TaskID;
  messagesCacheKeys?: FetchMessagesCacheKey[];
  onLightboxOpen: OnLightboxOpenAction;
  updateProductCache: UpdateProductCacheAction<ProductsBlockListItemProduct>;
  onProductEditButtonMouseEnter?: (productNanoId: ProductNanoID) => void;
}

function ProductsBlockListItem({
  product,
  productsCacheKeys,
  projectId,
  taskId,
  messagesCacheKeys,
  onLightboxOpen,
  updateProductCache,
  onProductEditButtonMouseEnter
}: ProductsBlockListItemProps) {
  const currentUser = useCurrentUser();

  const handleOpenLightbox = useCallback(() => {
    if (product.image) {
      onLightboxOpen(product.image);
    }
  }, [product, onLightboxOpen]);

  const handleEditButtonMouseEnter = useCallback<() => void>(() => {
    onProductEditButtonMouseEnter?.(product.nanoId);
  }, [onProductEditButtonMouseEnter, product.nanoId]);

  return (
    <div className="w-80 max-w-full mx-auto relative rounded-md group">
      <ProductsListItemPreview
        product={product}
        onLightboxOpen={
          currentUser.hasPermissions(
            ProductsPermissions.READ_PRODUCT_PREVIEW_LINK
          )
            ? undefined
            : handleOpenLightbox
        }
      />
      <div className="text-sm p-3">
        <div className="font-medium truncate">
          <NextPureTooltipIconLinkHelper
            href={ProductPath.show(product.nanoId)}
            text={product.name}
            tooltipI18nText={product.name}
            tooltipPlacement={TooltipPlacement.BOTTOM}
          />
        </div>
        <div className="text-gray-600 dark:text-gray-500 truncate">
          <ProductsListItemNda product={product} />
          <TextWithTooltip
            text={product.brand?.localizedName}
            tooltipPlacement={TooltipPlacement.BOTTOM}
          />
          <ProductsListItemSku product={product} />
        </div>
        <div className="mt-3 flex justify-between items-center relative z-5">
          <CheckPermissions
            action={ProductsPermissions.READ_PRODUCT_FAVORITE_BUTTON}
          >
            <ProductFavoriteButton
              product={product}
              productsCacheKeys={productsCacheKeys}
              updateProductCache={updateProductCache}
              className={
                product.favorite
                  ? 'focus:ring-offset-0 items-center rounded-full text-pink-600 hover:text-pink-500 flex p-0.5'
                  : 'focus:ring-offset-0 items-center rounded-full text-gray-400 dark:text-gray-500 hover:text-pink-600 dark:hover:text-pink-600 flex p-1'
              }
              icon={
                product.favorite
                  ? IconsEnum.HEART_SOLID
                  : IconsEnum.HEART_OUTLINE
              }
            />
          </CheckPermissions>
        </div>
      </div>
      {product.blocked ? (
        <div className="absolute inset-0 rounded-md bg-crossed bg-red-200 bg-opacity-10 pointer-events-none" />
      ) : null}
      <div className="absolute inset-0 pointer-events-none flex sm:opacity-0 group-hover:opacity-100 focus-within:opacity-100 items-start justify-end">
        <div className="flex flex-col space-y-1 pointer-events-auto p-2">
          <TooltipSingletonSourceWrapper
            placement={TooltipPlacement.LEFT}
            withArrow
          >
            <CheckPermissions
              action={
                ProductsPermissions.READ_PRODUCT_AI_SEARCH_FIND_ALTERNATIVES_BUTTON
              }
            >
              <CreateMessageFromAiProductsSearchByAngleButton
                productId={product.id}
                angleId={product.angleId}
                projectId={projectId}
                taskId={taskId}
                cacheKeys={messagesCacheKeys}
                tooltipI18nText={aiSearchKeys.findAlternatives}
                icon={IconsEnum.SEARCH_AI_OUTLINE}
                iconClassName="h-5 w-5"
                className="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow"
              />
            </CheckPermissions>
            <CheckPermissions
              action={ProductsPermissions.READ_PRODUCT_DOWNLOAD_BUTTON}
            >
              <DownloadProductButton
                productUuid={product.uuid}
                className="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow"
                icon={IconsEnum.DOWNLOAD_SOLID}
                iconClassName="h-5 w-5"
                tooltipI18nText={words.download}
                tooltipSingleton
              />
            </CheckPermissions>

            <CheckPermissions
              action={
                ProductsPermissions.READ_PRODUCT_SUBMIT_TO_TASK_OR_PROJECT_BUTTON
              }
            >
              <SubmitProductToProjectOrTaskModalButton product={product} />
            </CheckPermissions>

            <ProductsListItemOpenLightboxButton
              product={product}
              onLightboxOpen={handleOpenLightbox}
            />
            <CheckPermissions
              action={ProductsPermissions.READ_PRODUCT_COPY_LINK_BUTTON}
            >
              <ProductsCopyLinkButton
                product={product}
                className="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow"
                icon={IconsEnum.LINK_SOLID}
                iconClassName="h-5 w-5 stroke-1.75"
                tooltipI18nText={words.copyLink}
              />
            </CheckPermissions>
            <CheckPermissions
              action={ProductsPermissions.READ_PRODUCT_EDIT_BUTTON}
            >
              <ProductsListItemEditLink
                product={product}
                onMouseEnter={handleEditButtonMouseEnter}
              />
            </CheckPermissions>
          </TooltipSingletonSourceWrapper>
        </div>
      </div>
    </div>
  );
}

export default memo<ProductsBlockListItemProps>(ProductsBlockListItem);
