import { useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

import { ID } from '../../../../../../../../../../types';
import { UsersTabType } from '../../../../../../../../../users/usersTypes';

import { SEARCH_USERS_QUERY } from '../../../../../../../../../users/queries/usersSearch.query';

import { useCurrentUser } from '../../../../../../../../../../auth/hooks/useAuth';
import { usePreviousValue } from '../../../../../../../../../../common/hooks/usePreviousValue';
import { usePaginatedUsers } from '../../../../../../../../../users/hooks/usePaginatedUsers';

import { CheckableUsersListItemUserType } from '../../../CheckableUsersListItem';

import { UserCache } from '../../../../../../../../../users/UserCache';

const usersFinanceRoleFilter = (openedTab: UsersTabType) => {
  switch (openedTab) {
    case 'workers':
      return { financeRoleId: { isNotNull: true } };
    case 'clients':
      return { financeRoleId: { isNull: true } };
    default:
      return { financeRoleId: null };
  }
};

interface SearchResultsUsersListOptions {
  fullName?: string;
  groupIds?: ID[];
  tab: UsersTabType;
  teamIds?: ID[];
}

function useSearchResultsUsersList({
  fullName,
  groupIds,
  tab,
  teamIds
}: SearchResultsUsersListOptions) {
  const currentUser = useCurrentUser();

  const { users, usersError, usersFetched, filterUsers } =
    usePaginatedUsers<CheckableUsersListItemUserType>({
      cacheKey: UserCache.usersSearchCacheKey(tab),
      query: SEARCH_USERS_QUERY,
      initialLimit: 80,
      initialPage: 1,
      initialFilters: {
        ...usersFinanceRoleFilter(tab),
        fullName,
        groupIds,
        teamIds
      }
    });

  const prevTab = usePreviousValue(tab);

  const prevFullName = usePreviousValue(fullName);

  const prevGroupIds = usePreviousValue(groupIds);

  const prevTeamIds = usePreviousValue(teamIds);

  useEffect(() => {
    if (
      prevTab !== tab ||
      prevFullName !== fullName ||
      !isEqual(sortBy(prevGroupIds), sortBy(groupIds)) ||
      !isEqual(sortBy(prevTeamIds), sortBy(teamIds))
    ) {
      filterUsers({
        ...usersFinanceRoleFilter(tab),
        fullName,
        groupIds,
        teamIds
      });
    }
  }, [
    filterUsers,
    currentUser,
    fullName,
    prevFullName,
    prevTab,
    tab,
    prevGroupIds,
    groupIds,
    prevTeamIds,
    teamIds
  ]);

  return {
    users,
    usersError,
    usersFetched
  };
}

export default useSearchResultsUsersList;
