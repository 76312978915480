import { useState, useCallback } from 'react';

interface UseImageEditorOptions {
  toggleBackdrop?: () => void;
}

function useImageEditor({ toggleBackdrop }: UseImageEditorOptions = {}) {
  const [enabled, setEnabled] = useState<boolean>(false);
  const toggleEnabled = useCallback<() => void>(() => {
    setEnabled((prevState) => !prevState);
    toggleBackdrop?.();
  }, [setEnabled, toggleBackdrop]);

  const showEditor = useCallback<() => void>(() => {
    setEnabled(true);
    toggleBackdrop?.();
  }, [setEnabled, toggleBackdrop]);

  const hideEditor = useCallback<() => void>(() => {
    setEnabled(false);
    toggleBackdrop?.();
  }, [setEnabled, toggleBackdrop]);

  return { enabled, toggleEnabled, showEditor, hideEditor };
}

export default useImageEditor;
