import { FetchItemsLimit } from '../../../../../../../../types';
import { IndexRequestAction } from '../../reducers/indexRequestReducer';

import { LIMIT_ITEMS } from '../../infiniteIndexRequestConstants';

function limitItemsAction(nextLimit: FetchItemsLimit): IndexRequestAction {
  return {
    type: LIMIT_ITEMS,
    nextLimit
  };
}

export default limitItemsAction;
