import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName, I18nText } from '../../../../../types';
import { FetchMessagesCacheKey } from '../../../messagesTypes';
import { TaskID } from '../../../../tasks/tasksTypes';
import { ProjectID } from '../../../../projects/projectsTypes';
import { MatchResultItemAngleId } from '../../../../matchResults/matchResultsTypes';
import { ProductID } from '../../../../products/productsTypes';

import { useToastNotification } from '../../../../../common/hooks/useToastNotification';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useCreateMessageFromAiProductsSearchByAngle } from '../../../hooks/useCreateMessageFromAiProductsSearchByAngle';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { words } from '../../../../../locales/keys';

interface CreateMessageFromAiProductsSearchByAngleButtonProps {
  angleId: MatchResultItemAngleId;
  productId: ProductID;
  taskId?: TaskID;
  projectId?: ProjectID;
  cacheKeys?: FetchMessagesCacheKey[];
  icon?: IconsEnum;
  iconClassName?: ClassName;
  className?: ClassName;
  tooltipI18nText?: I18nText;
}

function CreateMessageFromAiProductsSearchByAngleButton({
  angleId,
  productId,
  taskId,
  projectId,
  cacheKeys,
  icon,
  iconClassName,
  className,
  tooltipI18nText
}: CreateMessageFromAiProductsSearchByAngleButtonProps) {
  const {
    createMessageFromAiProductsSearchByAngle,
    createMessageFromAiProductsSearchByAngleErrorMessage,
    createMessageFromAiProductsSearchByAngleLoading
  } = useCreateMessageFromAiProductsSearchByAngle<unknown>({
    cacheKeys
  });

  useShowToastOnErrorChange({
    error: createMessageFromAiProductsSearchByAngleErrorMessage
  });

  const { showToastI18nNotification } = useToastNotification({
    appearance: 'success',
    i18nMessage: words.success
  });

  const handleClick = useCallback<() => void>(() => {
    return createMessageFromAiProductsSearchByAngle({
      taskId,
      projectId,
      angleId,
      productId
    }).then(() => showToastI18nNotification());
  }, [
    taskId,
    projectId,
    angleId,
    productId,
    showToastI18nNotification,
    createMessageFromAiProductsSearchByAngle
  ]);

  return (
    <PureTooltipIconButtonHelper
      className={className}
      icon={icon}
      iconClassName={iconClassName}
      tooltipI18nText={tooltipI18nText}
      disabled={createMessageFromAiProductsSearchByAngleLoading}
      onClick={handleClick}
    />
  );
}

export default CreateMessageFromAiProductsSearchByAngleButton;
