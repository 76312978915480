import React, { useMemo } from 'react';

import {
  FetchProductsLimit,
  FetchProductsFilters,
  FetchProductsCacheKey,
  FetchProductsCacheKeys
} from '../../../productsTypes';
import { MatchResultUUID } from '../../../../matchResults/matchResultsTypes';

import { TaskID } from '../../../../tasks/tasksTypes';
import { ProjectID } from '../../../../projects/projectsTypes';
import { FetchMessagesCacheKey } from '../../../../messages/messagesTypes';

import { useMatchResultProducts } from '../../../hooks/useMatchResultProducts';

import { ProductsBlockList } from '../../../components/lists/ProductsBlockList';

import { LoadMoreButtonHelper } from '../../../../../helpers/buttons/LoadMoreButtonHelper';

const options = {
  staleTime: 1000 * 60 * 60
};

interface ProductsMatchResultBlockProps {
  matchResultUuid: MatchResultUUID;
  productsCacheKey: FetchProductsCacheKey;
  projectId?: ProjectID;
  taskId?: TaskID;
  messagesCacheKeys?: FetchMessagesCacheKey[];
  initialFilters: FetchProductsFilters;
  initialLimit: FetchProductsLimit;
}

function ProductsMatchResultBlock({
  matchResultUuid,
  productsCacheKey,
  projectId,
  taskId,
  messagesCacheKeys,
  initialFilters,
  initialLimit
}: ProductsMatchResultBlockProps) {
  const productsCacheKeys = useMemo<FetchProductsCacheKeys>(
    () => [productsCacheKey],
    [productsCacheKey]
  );

  const {
    products,
    productsError,
    productsTotalCount,
    productsFetched,
    productsFetchingNextPage,
    productsIsPlaceholderData,
    hasNextProductsPage,
    updateProductCache,
    loadMoreProducts,
    productsPage,
    productsLimit
  } = useMatchResultProducts({
    cacheKey: productsCacheKey,
    matchResultUuid,
    initialLimit,
    initialFilters,
    options
  });

  return (
    <>
      <ProductsBlockList
        products={products}
        productsFetched={productsFetched}
        productsError={productsError}
        productsIsPlaceholderData={productsIsPlaceholderData}
        productsTotalCount={productsTotalCount}
        productsCacheKeys={productsCacheKeys}
        loadingItemsCount={initialLimit}
        projectId={projectId}
        taskId={taskId}
        messagesCacheKeys={messagesCacheKeys}
        updateProductCache={updateProductCache}
      />
      {hasNextProductsPage && (
        <LoadMoreButtonHelper
          itemsTotalCount={productsTotalCount}
          page={productsPage}
          limit={productsLimit}
          loadMoreItems={loadMoreProducts}
          itemsFetchingNextPage={productsFetchingNextPage}
        />
      )}
    </>
  );
}

export default ProductsMatchResultBlock;
