import { ClassName } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  MessagesListMessageImageHandleOpenLightbox,
  MessagesListMessageImageSelectedLifestyleAttachment
} from '../MessagesListMessageImage';
import { MessagesListMessageImageLifestyleButtons } from './components/MessagesListMessageImageLifestyleButtons';

import {
  ImageHelper,
  FileAttachmentItemImageVersions
} from '../../../../../../../helpers/ImageHelper';
import { LinkHelper } from '../../../../../../../helpers/links/LinkHelper';
import { Icon } from '../../../../../../../helpers/Icon';

interface MessagesListMessageImageLifestyleProps {
  linkClassName: ClassName;
  imageClassName: ClassName;
  attachment: MessagesListMessageImageSelectedLifestyleAttachment;
  handleOpenLightbox: MessagesListMessageImageHandleOpenLightbox;
  tooltipSingleton?: boolean;
}

function MessagesListMessageImageLifestyle({
  linkClassName,
  imageClassName,
  attachment,
  handleOpenLightbox,
  tooltipSingleton
}: MessagesListMessageImageLifestyleProps) {
  return (
    <div className={linkClassName}>
      <MessagesListMessageImageLifestyleButtons attachment={attachment} />
      <LinkHelper
        href={attachment.lifestyle.image.file}
        target="_blank"
        onClick={handleOpenLightbox}
      >
        <ImageHelper
          className={imageClassName}
          src={attachment.lifestyle.image.file}
          alt={attachment.lifestyle.name}
          version={FileAttachmentItemImageVersions.BigThumb538x435}
          tooltipSingleton={tooltipSingleton}
          tooltipAlt
          tooltipAltInteractive
          tooltipAltAppendToBody
        />
        <div className="absolute bottom-0 left-0 p-1 bg-pink-600 text-white flex rounded-bl rounded-tr">
          <Icon icon={IconsEnum.RULERS_OUTLINE} className="w-5 h-5" />
        </div>
      </LinkHelper>
    </div>
  );
}

export default MessagesListMessageImageLifestyle;
