import React from 'react';

import { BillUUID, CheckBillIndexCacheKey } from '../../../billsTypes';

import { IconsEnum } from '../../../../../assets/icons/types';

import { useCheckBillButton } from './hooks/useCheckBillButton';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

import { invoicesKeys } from '../../../../../locales/keys';

interface CheckBillButtonProps {
  indexCacheKey?: CheckBillIndexCacheKey;
  billUuid: BillUUID;
}

function CheckBillButton({ indexCacheKey, billUuid }: CheckBillButtonProps) {
  const { checkBillLoading, handleCheckBill } = useCheckBillButton({
    indexCacheKey,
    uuid: billUuid
  });

  return (
    <PureIconButtonHelper
      className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      disabled={checkBillLoading}
      i18nText={invoicesKeys.refreshStatus}
      icon={IconsEnum.REFRESH_SOLID}
      iconClassName="h-5 w-5 mr-2"
      onClick={handleCheckBill}
    />
  );
}

export default CheckBillButton;
