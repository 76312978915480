import { useCallback } from 'react';

import { IconsEnum } from '../../../../assets/icons/types';

import { UserAvatarLink, UserAvatarLinkUser } from '../UserAvatarLink';

import { PureIconButtonHelper } from '../../../../helpers/buttons/PureIconButtonHelper';

interface RemovableUserAvatarLinkDefaultProps {
  user: UserAvatarLinkUser;
  onRemove: (user: UserAvatarLinkUser) => void;
}

interface RemovableUserAvatarLinkWithClassNameProps {
  className: string;
  avatarType?: never;
}

interface RemovableUserAvatarLinkWithAvatarTypeProps {
  className?: never;
  avatarType: 'main' | 'xl' | 'xs';
}

type RemovableUserAvatarLinkProps = RemovableUserAvatarLinkDefaultProps &
  (
    | RemovableUserAvatarLinkWithClassNameProps
    | RemovableUserAvatarLinkWithAvatarTypeProps
  );

function RemovableUserAvatarLink({
  avatarType,
  onRemove,
  user
}: RemovableUserAvatarLinkProps) {
  const handleRemoveUser = useCallback(
    () => onRemove?.(user),
    [onRemove, user]
  );

  if (!user) {
    return null;
  }

  return (
    <div className="relative">
      <UserAvatarLink avatarType={avatarType} user={user} />

      <div className="absolute -top-2 -right-2">
        <PureIconButtonHelper
          className="py-0.5 pl-0.5 pr-0.5 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
          icon={IconsEnum.X}
          iconClassName="w-4 h-4"
          onClick={handleRemoveUser}
        />
      </div>
    </div>
  );
}

export default RemovableUserAvatarLink;
