import React, { useCallback, useState } from 'react';
import find from 'lodash/find';

import { ID } from '../../../../../../../types';
import { MatchResultUUID } from '../../../../../../matchResults/matchResultsTypes';
import { ProductCategoryID } from '../../../../../../products/productsTypes';
import { useMatchSingleObjectByDetectedBounding } from '../../../../../../matchResults/hooks/useMatchSingleObjectByDetectedBounding';
import { FetchMessagesCacheKey } from '../../../../../messagesTypes';

import { ModelingCategorySlug } from '../../../../../../modelingCategories/modelingCategoriesTypes';

import { useModelingCategories } from '../../../../../../modelingCategories/hooks/useModelingCategories';

import {
  ModelingCategoriesSelect,
  ModelingCategoriesSelectCategory
} from '../../../../../../modelingCategories/helpers/ModelingCategoriesSelect';
import { ItemMessagesListAiSearchMessageHeader } from '../ItemMessagesListAiSearchMessage/components/ItemMessagesListAiSearchMessageHeader';
import { ItemMessagesListAiSearchMessageBody } from '../ItemMessagesListAiSearchMessage/components/ItemMessagesListAiSearchMessageBody';
import { ItemMessagesListMessageSendEmailNotificationButton } from '../ItemMessagesListMessageSendEmailNotificationButton';
import {
  ItemMessagesListAiDetectSearchMessageImage,
  ItemMessagesListAiDetectSearchMessageImageDetectedItem
} from './components/ItemMessagesListAiDetectSearchMessageImage';

import { ItemMessagesListAiDetectSearchMessageItem } from './ItemMessagesListAiDetectSearchMessage.types';

import { AlertMessage } from '../../../../../../../helpers/AlertMessage';
import { Loading } from '../../../../../../../helpers/Loading';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { PureButtonHelper } from '../../../../../../../helpers/buttons/PureButtonHelper';

import { MessageBffBodyData } from '../../../../../../../utils/MessageBffBodyData';

import { MessagesPermissions } from '../../../../../messagesConstants';

import { normalizeModelingCategorySlug } from '../../../../../../modelingCategories/utils/normalizeModelingCategorySlug';

import { aiSearchKeys, words } from '../../../../../../../locales/keys';

interface ItemMessagesListAiDetectSearchMessageProps {
  message: ItemMessagesListAiDetectSearchMessageItem;
  messagesCacheKey: FetchMessagesCacheKey;
}
function ItemMessagesListAiDetectSearchMessage({
  message,
  messagesCacheKey
}: ItemMessagesListAiDetectSearchMessageProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleIsOpen = useCallback<() => void>(
    () => setIsOpen((prevState) => !prevState),
    []
  );

  const bffData = MessageBffBodyData.parseBffData<{
    detectedItems: ItemMessagesListAiDetectSearchMessageImageDetectedItem[];
  }>(message.body);

  const detectedItems = bffData?.detectedItems;

  const {
    matchSingleObjectByDetectedBounding,
    matchSingleObjectByDetectedBoundingLoading,
    matchSingleObjectByDetectedBoundingErrorMessage
  } = useMatchSingleObjectByDetectedBounding({});

  const { modelingCategories } = useModelingCategories();

  const [categorySlugByBoundingId, setCategorySlugByBoundingId] = useState<
    Record<ID, ModelingCategorySlug>
  >({});

  const [boundingId, setBoundingId] = useState<ID>(null);

  const categorySlug = categorySlugByBoundingId?.[boundingId];
  const productCategoryId = find(modelingCategories, [
    'slug',
    categorySlug
  ])?.externalId;

  const handleBoundingIdChange = useCallback<(boundingId: ID) => void>(
    (boundingId) => {
      setBoundingId(boundingId);
      if (!categorySlugByBoundingId?.[boundingId]) {
        const defaultCategorySlug = normalizeModelingCategorySlug(
          find(detectedItems, ['id', boundingId])?.category
        );

        setCategorySlugByBoundingId((prev) => ({
          ...prev,
          [boundingId]: defaultCategorySlug
        }));
      }
    },
    [categorySlugByBoundingId, detectedItems]
  );

  const handleCategoryChange = useCallback<
    (category: ModelingCategoriesSelectCategory) => void
  >(
    (category) => {
      if (boundingId) {
        setCategorySlugByBoundingId((prev) => ({
          ...prev,
          [boundingId]: category?.slug
        }));
      }
    },
    [boundingId, setCategorySlugByBoundingId]
  );

  const [matchUuidByDetectItemId, setMatchUuidByDetectItemId] = useState<
    Record<ID, Record<'default' | ProductCategoryID, MatchResultUUID>>
  >({});

  const handleMatch = useCallback<({ boundingId, productCategoryId }) => void>(
    ({ boundingId, productCategoryId }) => {
      const matchResultUuid = productCategoryId
        ? matchUuidByDetectItemId?.[boundingId]?.[productCategoryId]
        : matchUuidByDetectItemId?.[boundingId]?.default;

      if (!matchResultUuid) {
        matchSingleObjectByDetectedBounding({
          boundingId,
          productCategoryId,
          taskId: message.taskId,
          projectId: message.projectId
        }).then((data) => {
          setMatchUuidByDetectItemId((prev) => ({
            ...prev,
            [boundingId]: {
              ...prev?.[boundingId],
              ...(productCategoryId
                ? {
                    [productCategoryId]: data.record.id
                  }
                : { default: data.record.id })
            }
          }));
        });
      }
    },
    [message, matchUuidByDetectItemId, matchSingleObjectByDetectedBounding]
  );

  const handleSearch = useCallback<() => void>(() => {
    if (boundingId) {
      handleMatch({
        boundingId: boundingId,
        productCategoryId
      });
    }
  }, [boundingId, productCategoryId, handleMatch]);

  const matchResultUuid = productCategoryId
    ? matchUuidByDetectItemId?.[boundingId]?.[productCategoryId]
    : matchUuidByDetectItemId?.[boundingId]?.default;

  const isSearchDisabled =
    !boundingId ||
    !!matchResultUuid ||
    matchSingleObjectByDetectedBoundingLoading;

  return (
    <div className="mb-10" id={`message-${message.uuid}`}>
      <div className="flex flex-col flex-1">
        <div className="w-full">
          <div className="rounded-lg border border-gray-200 dark:border-gray-700">
            <ItemMessagesListAiSearchMessageHeader
              customI18nTitle={aiSearchKeys.aiPoweredSearchDetect}
              message={message}
              isOpen={isOpen}
              toggleIsOpen={toggleIsOpen}
            />
            {isOpen ? (
              <>
                <ItemMessagesListAiDetectSearchMessageImage
                  detectedItems={detectedItems}
                  selectedBoundingId={boundingId}
                  message={message}
                  onSelectChange={handleBoundingIdChange}
                />
                <CheckPermissions
                  action={
                    MessagesPermissions.CHANGE_MESSAGE_AI_DETECT_SEARCH_CATEGORY
                  }
                >
                  <div className="p-2 flex bg-gray-50 dark:bg-gray-800">
                    <div className="mx-auto flex items-center">
                      <div className="min-w-60">
                        <ModelingCategoriesSelect
                          value={categorySlug}
                          disabled={!boundingId}
                          onChange={handleCategoryChange}
                        />
                      </div>
                      <PureButtonHelper
                        className="ml-1 py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md"
                        disabled={isSearchDisabled}
                        i18nText={words.search}
                        onClick={handleSearch}
                      />
                    </div>
                  </div>
                </CheckPermissions>
                <AlertMessage
                  message={matchSingleObjectByDetectedBoundingErrorMessage}
                />
                <Loading
                  addClass="w-full p-4"
                  loaded={!matchSingleObjectByDetectedBoundingLoading}
                >
                  {matchResultUuid ? (
                    <ItemMessagesListAiSearchMessageBody
                      key={matchResultUuid}
                      matchResultUuid={matchResultUuid}
                      message={message}
                      messagesCacheKey={messagesCacheKey}
                    />
                  ) : null}
                </Loading>
              </>
            ) : null}
          </div>

          <ItemMessagesListMessageSendEmailNotificationButton
            message={message}
            messagesCacheKey={messagesCacheKey}
            className="flex items-center justify-center mt-1"
          />
        </div>
      </div>
    </div>
  );
}
export default ItemMessagesListAiDetectSearchMessage;
