import React, { useMemo, useCallback, useEffect, Fragment } from 'react';
import compact from 'lodash/compact';
import concat from 'lodash/concat';
import uniqBy from 'lodash/uniqBy';

import { ProjectUUID } from '../../../projects/projectsTypes';

import {
  FETCH_SELECT_FIELD_TASKS_QUERY,
  FetchSelectFieldTasksQueryResponse
} from '../../queries/fetchSelectFieldTasks.query';

import { useTasks } from '../../hooks/useTasks';

import {
  MultiSelectField,
  MultiSelectFieldProps,
  MultiSelectFieldControlProps
} from '../../../../helpers/FormFields/MultiSelectField';
import {
  MultiSelectDataType,
  MultiSelectInputChangeCallbackType
} from '../../../../helpers/MultiSelect/types';

import { AlertMessage } from '../../../../helpers/AlertMessage';

import { TaskCache } from '../../TaskCache';

interface TasksByProjectSelectFieldProps {
  projectUuid: ProjectUUID;
  multi: boolean;
  tasksSelectDefaultValue?: MultiSelectDataType[];
  currentProjectOption?: MultiSelectDataType;
  valueType?: 'uuid' | 'nanoId';
}

function TasksByProjectSelectField<T>({
  projectUuid,
  control,
  error,
  name,
  emptyValue,
  i18nPlaceholder,
  inputWrapperClassName,
  i18nLabel,
  labelClassName,
  disabled,
  multi,
  currentProjectOption,
  tasksSelectDefaultValue,
  valueType = 'uuid',
  closeMenuOnSelect
}: TasksByProjectSelectFieldProps &
  MultiSelectFieldControlProps<T> &
  MultiSelectFieldProps<T>) {
  const {
    tasks,
    tasksLoading,
    tasksError,
    changeTasksFilters,
    loadMoreTasks,
    tasksFetchingNextPage
  } = useTasks<FetchSelectFieldTasksQueryResponse>({
    query: FETCH_SELECT_FIELD_TASKS_QUERY,
    cacheKey: TaskCache.selectByProjectFieldCacheKey(projectUuid),
    initialFilters: { projectUuid }
  });

  useEffect(() => {
    changeTasksFilters({ projectUuid });
  }, [projectUuid, changeTasksFilters]);

  const tasksSelectData = useMemo<MultiSelectDataType[]>(() => {
    return tasks.map((task) => ({
      label: task.name,
      value: valueType === 'uuid' ? task.uuid : task.nanoId
    }));
  }, [tasks, valueType]);

  const tasksSelectDataWithDefaultValue = uniqBy(
    compact(
      concat(tasksSelectDefaultValue, currentProjectOption, tasksSelectData)
    ),
    'value'
  );

  const handleInputChange = useCallback<MultiSelectInputChangeCallbackType>(
    (input) => {
      changeTasksFilters(
        { name: { ilike: input } },
        compact([input ? null : 'name'])
      );
    },
    [changeTasksFilters]
  );

  return (
    <Fragment>
      <MultiSelectField<T>
        control={control}
        error={error}
        name={name}
        emptyValue={emptyValue}
        data={tasksSelectDataWithDefaultValue}
        i18nPlaceholder={i18nPlaceholder}
        inputWrapperClassName={inputWrapperClassName}
        i18nLabel={i18nLabel}
        labelClassName={labelClassName}
        disabled={disabled}
        optionsLoading={tasksLoading}
        classNamePrefix="av"
        multi={multi}
        menuPosition="fixed"
        isSearchable
        onInputChange={handleInputChange}
        onMenuScrollToBottom={loadMoreTasks}
        isLoading={tasksFetchingNextPage}
        closeMenuOnSelect={closeMenuOnSelect}
      />
      <AlertMessage addClassName="mt-2" message={tasksError} />
    </Fragment>
  );
}

export default TasksByProjectSelectField;
