import {
  MessageAttachmentTypes,
  MessageFinAttachmentTypes
} from '../../../../../../../messagesTypes';
import {
  MessagesListMessageImageFileAttachmentAttachment,
  MessagesListMessageImageSelectedProductAttachment,
  MessagesListMessageImageSelectedLifestyleAttachment,
  MessagesListMessageImageSelectedMaterialAttachment
} from '../../index';

function mapMessageAttachmentImage(
  attachment:
    | MessagesListMessageImageFileAttachmentAttachment
    | MessagesListMessageImageSelectedProductAttachment
    | MessagesListMessageImageSelectedLifestyleAttachment
    | MessagesListMessageImageSelectedMaterialAttachment
) {
  switch (attachment.type) {
    case MessageAttachmentTypes.FILE_ATTACHMENT:
      return attachment;
    case MessageAttachmentTypes.SELECTED_PRODUCT:
      return attachment.product?.image;
    case MessageAttachmentTypes.SELECTED_LIFESTYLE:
      return attachment.lifestyle?.image;
    case MessageAttachmentTypes.SELECTED_MATERIAL:
      return attachment.material?.image;
    case MessageFinAttachmentTypes.FILE_ATTACHMENT:
      return attachment;
    case MessageFinAttachmentTypes.SELECTED_PRODUCT:
      return attachment.product?.image;
    case MessageFinAttachmentTypes.SELECTED_LIFESTYLE:
      return attachment.lifestyle?.image;
    case MessageFinAttachmentTypes.SELECTED_MATERIAL:
      return attachment.material?.image;
  }
}

export default mapMessageAttachmentImage;
