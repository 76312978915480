import React, { useCallback, useState } from 'react';

import { MatchResultUUID } from '../../../../../../matchResults/matchResultsTypes';
import { ProductCategoryID } from '../../../../../../products/productsTypes';

import { ItemMessagesListAiSearchMessageHeader } from './components/ItemMessagesListAiSearchMessageHeader';
import { ItemMessagesListAiSearchMessageBody } from './components/ItemMessagesListAiSearchMessageBody';
import { ItemMessagesListMessageSendEmailNotificationButton } from '../ItemMessagesListMessageSendEmailNotificationButton';

import { ItemMessagesListAiSearchMessageItem } from './ItemMessagesListAiSearchMessage.types';
import { FetchMessagesCacheKey } from '../../../../../messagesTypes';

import { MessageBffBodyData } from '../../../../../../../utils/MessageBffBodyData';

interface ItemMessagesListAiSearchMessageProps {
  message: ItemMessagesListAiSearchMessageItem;
  messagesCacheKey: FetchMessagesCacheKey;
}
function ItemMessagesListAiSearchMessage({
  message,
  messagesCacheKey
}: ItemMessagesListAiSearchMessageProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleIsOpen = useCallback<() => void>(
    () => setIsOpen((prevState) => !prevState),
    []
  );

  const bffData = MessageBffBodyData.parseBffData<{
    matchingUuid: MatchResultUUID;
    productCategoryId: ProductCategoryID;
  }>(message.body);

  const matchResultUuid: MatchResultUUID = bffData?.matchingUuid;
  const productCategoryId = bffData?.productCategoryId;

  return (
    <div className="mb-10" id={`message-${message.uuid}`}>
      <div className="flex flex-col flex-1">
        <div className="w-full">
          <div className="rounded-lg border border-gray-200 dark:border-gray-700">
            <ItemMessagesListAiSearchMessageHeader
              message={message}
              productCategoryId={productCategoryId}
              isOpen={isOpen}
              toggleIsOpen={toggleIsOpen}
            />
            {isOpen ? (
              <ItemMessagesListAiSearchMessageBody
                matchResultUuid={matchResultUuid}
                message={message}
                messagesCacheKey={messagesCacheKey}
              />
            ) : null}
          </div>

          <ItemMessagesListMessageSendEmailNotificationButton
            message={message}
            messagesCacheKey={messagesCacheKey}
            className="flex items-center justify-center mt-1"
          />
        </div>
      </div>
    </div>
  );
}
export default ItemMessagesListAiSearchMessage;
