import React, { useMemo, useCallback } from 'react';
import first from 'lodash/first';
import compact from 'lodash/compact';
import toString from 'lodash/toString';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { I18nText } from '../../../../../../../../../types';
import { ProductCategoryID } from '../../../../../../../../products/productsTypes';

import { ItemMessagesListAiSearchMessageHeaderMessage } from './ItemMessagesListAiSearchMessageHeader.types';

import { ProductsCategoryLocalizedNameById } from '../../../../../../../../products/helpers/ProductsCategoryLocalizedNameById';

import { Translate } from '../../../../../../../../../helpers/Translate';
import { LinkHelper } from '../../../../../../../../../helpers/links/LinkHelper';
import { PureIconButtonHelper } from '../../../../../../../../../helpers/buttons/PureIconButtonHelper';
import {
  FileAttachmentItemImageVersions,
  ImageHelper
} from '../../../../../../../../../helpers/ImageHelper';
import {
  LightboxWrapper,
  useLightboxWrapper,
  LightboxItems
} from '../../../../../../../../../helpers/LightboxWrapper';

import { Files } from '../../../../../../../../../utils/Files';

import { aiSearchKeys } from '../../../../../../../../../locales/keys';

interface ItemMessagesListAiSearchMessageHeaderProps {
  message: ItemMessagesListAiSearchMessageHeaderMessage;
  productCategoryId?: ProductCategoryID;
  customI18nTitle?: I18nText;
  isOpen?: boolean;
  toggleIsOpen: () => void;
}
function ItemMessagesListAiSearchMessageHeader({
  customI18nTitle,
  message,
  productCategoryId,
  isOpen,
  toggleIsOpen
}: ItemMessagesListAiSearchMessageHeaderProps) {
  const fileAttachment = first(message?.fileAttachments);
  const selectedProduct = first(message?.selectedProducts);

  const lightboxItem = fileAttachment || selectedProduct?.product?.image;

  const lightboxItems = useMemo<LightboxItems>(
    () => compact([lightboxItem]),
    [lightboxItem]
  );

  const {
    handleLightboxClose,
    handleLightboxNext,
    handleLightboxOpen,
    handleLightboxOpenOnSlide,
    handleLightboxPrev,
    index,
    imagesCount,
    imageItem,
    lightBoxOpened,
    mainSrc,
    prevSrc,
    nextSrc
  } = useLightboxWrapper({
    items: lightboxItems
  });

  const handleOpenLightbox = useCallback<
    (e: React.MouseEvent<HTMLAnchorElement>) => void
  >(
    (e) => {
      if (lightboxItem && Files.isImage(lightboxItem.file)) {
        e.preventDefault();
        handleLightboxOpenOnSlide(lightboxItem);
      }
    },
    [lightboxItem, handleLightboxOpenOnSlide]
  );

  return (
    <div className="relative p-2 sm:p-4 bg-gray-50 dark:bg-gray-800 rounded-t-lg">
      <div className="absolute -top-px -right-px flex -space-x-px">
        <PureIconButtonHelper
          key={toString(isOpen)}
          className="focus:ring-0 rounded-tr-lg rounded-bl-lg p-1.5 flex justify-center items-center border dark:border-gray-800 hover:bg-gray-200 dark:hover:bg-gray-800"
          icon={isOpen ? IconsEnum.CHEVRON_UP : IconsEnum.CHEVRON_DOWN_SOLID}
          onClick={toggleIsOpen}
        />
      </div>
      <div className="relative flex gap-4">
        <div className="shrink-0 flex items-center">
          <LinkHelper
            className="block focus:ring-base overflow-hidden focus:ring-offset-0 rounded-md"
            href={lightboxItem?.file}
            target="_blank"
            onClick={handleOpenLightbox}
          >
            <ImageHelper
              className="h-20 max-w-36"
              src={lightboxItem?.file}
              alt={lightboxItem?.name}
              version={FileAttachmentItemImageVersions.BigThumb538x435}
            />
          </LinkHelper>

          <LightboxWrapper
            handleLightboxClose={handleLightboxClose}
            handleLightboxNext={handleLightboxNext}
            handleLightboxOpen={handleLightboxOpen}
            handleLightboxPrev={handleLightboxPrev}
            index={index}
            imagesCount={imagesCount}
            imageItem={imageItem}
            lightBoxOpened={lightBoxOpened}
            mainSrc={mainSrc}
            nextSrc={nextSrc}
            prevSrc={prevSrc}
            withFullScreenButton
            withBaseImageTitle
          />
        </div>

        <div className="flex-1 py-2 flex items-center min-w-0">
          <div className="w-full">
            <div className="flex items-center mb-1.5 gap-1">
              <div className="uppercase font-bold text-gray-500 text-sm">
                <Translate
                  id={customI18nTitle || aiSearchKeys.aiPoweredSearch}
                />
              </div>
              <button
                type="button"
                data-tippy="default"
                data-tippy-content="How it works"
                data-modal-open="modal-ai-search-help"
                className="py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0 inline-flex"
              ></button>
            </div>
            <p className="truncate w-full flex text-md">
              <span className="truncate">
                {fileAttachment ? fileAttachment.name : null}
                {selectedProduct ? selectedProduct.product?.name : null}
              </span>
            </p>
            <div className="text-xs text-gray-500">
              {fileAttachment ? (
                <Translate id={aiSearchKeys.sourceImage} />
              ) : null}
              {selectedProduct ? (
                <Translate id={aiSearchKeys.sourceProduct} />
              ) : null}
            </div>
            {productCategoryId ? (
              <div className="text-xs text-gray-500">
                <ProductsCategoryLocalizedNameById
                  productCategoryId={productCategoryId}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemMessagesListAiSearchMessageHeader;
