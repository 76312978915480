import React, { memo, useCallback, useMemo } from 'react';
import includes from 'lodash/includes';
import map from 'lodash/map';
import size from 'lodash/size';

import {
  MultiSelectInputChangeCallbackType,
  MultiSelectDataType
} from '../../MultiSelect/types';
import { ID } from '../../../types';

import { FiltersProps, useFilters } from '../hooks/useFilters';

import { MultiSelect } from '../../MultiSelect';

type MultiSelectFilterValue = ID | ID[] | null | undefined;

interface MultiSelectFilterProps extends FiltersProps<MultiSelectFilterValue> {
  data?: MultiSelectDataType[];
  defaultValue?: MultiSelectDataType[] | MultiSelectDataType | null;
  disabled?: boolean;
  isClearable?: boolean;
  multi?: boolean;
  selectedValue?: MultiSelectDataType[];
  inputWrapperClassName?: string;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  optionsLoading?: boolean;
  placeholder?: string;
  i18nPlaceholder?: string;
  onInputChange?: MultiSelectInputChangeCallbackType;
}

function MultiSelectFilter({
  defaultValue,
  name,
  value,
  onChange,
  data = [],
  disabled,
  multi = true,
  selectedValue = [],
  inputWrapperClassName,
  menuPlacement = 'auto',
  optionsLoading = false,
  placeholder,
  i18nPlaceholder,
  onInputChange,
  isClearable
}: MultiSelectFilterProps) {
  const { updatedValue, handleChange } = useFilters<MultiSelectFilterValue>({
    name,
    value,
    emptyValue: [],
    onChange
  });

  const handleInputChange = useCallback(
    (newValue) => {
      if (!newValue) {
        handleChange(null);
        return;
      }

      if (multi) {
        handleChange(map(newValue, (item) => item.value));
        return;
      }

      handleChange(newValue.value);
    },
    [handleChange, multi]
  );

  const currentSelectValue = useMemo(() => {
    if (size(selectedValue) > 0) {
      return selectedValue;
    }

    if (!updatedValue) {
      return null;
    }

    if (multi) {
      return data.filter((item) =>
        includes(updatedValue as ID[] | null, item.value)
      );
    }

    return data.find((item) => item.value === updatedValue);
  }, [selectedValue, updatedValue, data, multi]);

  return (
    <MultiSelect
      defaultValue={defaultValue}
      name={`${name}_multi_select`}
      isClearable={isClearable}
      isSearchable
      optionsLoading={optionsLoading}
      inputWrapperClassName={inputWrapperClassName}
      multi={multi}
      data={data}
      value={currentSelectValue}
      placeholder={placeholder}
      i18nPlaceholder={i18nPlaceholder}
      menuPlacement={menuPlacement}
      menuPosition="fixed"
      onChange={handleInputChange}
      onInputChange={onInputChange}
      disabled={disabled}
      classNamePrefix="av"
    />
  );
}

export default memo(MultiSelectFilter);
