import React, { useCallback } from 'react';

import { UUID } from '../../../../types';

import { IconsEnum } from '../../../../assets/icons/types';

import { PureTooltipIconButtonHelper } from '../../../buttons/PureTooltipIconButtonHelper';

import { words } from '../../../../locales/keys';

export interface LightboxWrapperDownloadButtonProps {
  uuid: UUID;
  onDownload: (uuid: UUID) => void;
}

function LightboxWrapperDownloadButton({
  uuid,
  onDownload
}: LightboxWrapperDownloadButtonProps) {
  const handleDownload = useCallback<() => void>(() => {
    onDownload(uuid);
  }, [onDownload, uuid]);

  return (
    <PureTooltipIconButtonHelper
      id="download-from-image"
      onClick={handleDownload}
      className="focus:outline-none focus:ring-2 py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-white focus:ring-offset-0 hover:bg-white hover:bg-opacity-10 text-gray-300 hover:text-white"
      iconClassName="h-6 w-6"
      icon={IconsEnum.DOWNLOAD_OUTLINE}
      tooltipI18nText={words.download}
      tooltipSingleton
    />
  );
}

export default LightboxWrapperDownloadButton;
