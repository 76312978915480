import React, { useState } from 'react';

import { I18nText } from '../../../types';

import { IconsEnum } from '../../../assets/icons/types';

import { Icon } from '../../../helpers/Icon';
import { Tooltip } from '../../tooltips/Tooltip';
import { TooltipSingletonTarget } from '../../tooltips/TooltipSingletonTarget';
import { Translate } from '../../Translate';

import { TooltipPlacement } from '../../tooltips/tooltipsConstants';

type PureTooltipIconLinkHelperOnClick = (
  e: React.MouseEvent<HTMLAnchorElement>
) => void;

export interface PureTooltipIconLinkHelperDefaultProps {
  href: string;
  target?: '_blank';
  rel?: string;
  className?: string;
  iconClassName?: string;
  onClick?: PureTooltipIconLinkHelperOnClick;
  tooltipI18nText: string;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
}

export interface PureTooltipIconLinkHelperIconProps {
  icon: IconsEnum | null;
  i18nTextClassName?: never;
  i18nText?: never;
  text?: never;
}

interface PureTooltipIconLinkHelperWithI18nProps {
  icon: IconsEnum | null;
  i18nTextClassName?: string;
  i18nText: I18nText;
  text?: never;
}

interface PureTooltipIconLinkHelperWithTextProps {
  icon: IconsEnum | null;
  i18nTextClassName?: never;
  i18nText?: never;
  text: string;
}

type PureTooltipIconLinkHelperProps = PureTooltipIconLinkHelperDefaultProps &
  (
    | PureTooltipIconLinkHelperIconProps
    | PureTooltipIconLinkHelperWithI18nProps
    | PureTooltipIconLinkHelperWithTextProps
  );

function PureTooltipIconLinkHelper({
  href,
  target,
  rel,
  className,
  icon = null,
  iconClassName,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton,
  onClick,
  i18nTextClassName,
  i18nText,
  text
}: PureTooltipIconLinkHelperProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  return (
    <a
      href={href}
      target={target}
      rel={rel}
      className={className}
      onClick={onClick}
      ref={setReferenceTooltipElement}
    >
      {icon ? <Icon className={iconClassName} icon={icon} /> : null}
      {icon && i18nText ? ' ' : null}
      {i18nText && i18nTextClassName ? (
        <span className={i18nTextClassName}>
          <Translate id={i18nText} />
        </span>
      ) : null}
      {i18nText && !i18nTextClassName ? <Translate id={i18nText} /> : null}
      {text}
      {tooltipSingleton ? (
        <TooltipSingletonTarget
          referenceElement={referenceTooltipElement}
          tooltipI18nText={tooltipI18nText}
        />
      ) : (
        <Tooltip
          withArrow
          referenceElement={referenceTooltipElement}
          tooltipI18nText={tooltipI18nText}
          placement={tooltipPlacement}
        />
      )}
    </a>
  );
}

export default PureTooltipIconLinkHelper;
