import {
  FetchTasksFilters,
  FetchTasksSort,
  FetchTasksLimit,
  FetchTasksGqlQuery,
  FetchTasksCacheKey
} from '../../tasksTypes';

import {
  INITIAL_TASKS_FILTERS,
  INITIAL_TASKS_LIMIT,
  INITIAL_TASKS_SORT
} from '../../tasksConstants';

import {
  useInfiniteIndexQuery,
  InfiniteIndexQueryBaseNodeType
} from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

interface TasksOptions {
  cacheKey: FetchTasksCacheKey;
  query: FetchTasksGqlQuery;
  initialFilters?: FetchTasksFilters;
  initialSort?: FetchTasksSort;
  initialLimit?: FetchTasksLimit;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

const scope = 'tasks';

function useTasks<TaskItemType extends InfiniteIndexQueryBaseNodeType>({
  cacheKey,
  query,
  initialFilters = INITIAL_TASKS_FILTERS,
  initialSort = INITIAL_TASKS_SORT,
  initialLimit = INITIAL_TASKS_LIMIT,
  options = {}
}: TasksOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    clearItemsFilters,
    filterItems,
    sortItems,
    limitItems,
    changeItemsFilters
  } = useInfiniteIndexQuery<TaskItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialSort,
    options,
    query,
    scope
  });

  return {
    tasksData: data,
    tasks: items,
    tasksError: itemsError,
    tasksTotalCount: itemsTotalCount,
    tasksFetched: isFetched,
    tasksLoading: isLoading,
    tasksFetchingNextPage: isFetchingNextPage,
    tasksIsPlaceholderData: isPlaceholderData,
    tasksFilters: currentFilters,
    tasksSort: currentSort,
    tasksPage: currentPage,
    tasksLimit: currentLimit,
    hasNextTasksPage: hasNextPage,
    updateTaskCache: updateItemCache,
    loadMoreTasks: loadMoreItems,
    filterTasks: filterItems,
    clearTasksFilters: clearItemsFilters,
    sortTasks: sortItems,
    limitTasks: limitItems,
    changeTasksFilters: changeItemsFilters
  };
}

export default useTasks;
