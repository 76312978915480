import React, { useState } from 'react';

import { I18nText, ClassName } from '../../types';
import { IconsEnum } from '../../assets/icons/types';

import { Icon } from '../Icon';
import { Tooltip } from '../tooltips/Tooltip';

import { TooltipPlacement } from '../tooltips/tooltipsConstants';
import { Translate } from '../Translate';

interface IconWithTooltipWithI18nTextProps {
  i18nText?: I18nText | null;
  text?: never;
}

interface IconWithTooltipWithTextProps {
  i18nText?: never;
  text?: string;
}

interface IconWithTooltipDefaultProps {
  className?: ClassName;
  icon: IconsEnum;
  iconClassName?: ClassName;
  i18nText?: I18nText | null;
}

type IconWithTooltipProps = IconWithTooltipDefaultProps &
  (IconWithTooltipWithI18nTextProps | IconWithTooltipWithTextProps);

function IconWithTooltip({
  className,
  icon,
  iconClassName,
  i18nText,
  text
}: IconWithTooltipProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  if (!i18nText && !text) {
    return null;
  }

  return (
    <>
      <span className={className} ref={setReferenceTooltipElement}>
        <Icon className={iconClassName} icon={icon} />
      </span>

      <Tooltip
        referenceElement={referenceTooltipElement}
        placement={TooltipPlacement.TOP}
        offset={[0, 14]}
        withArrow
      >
        <h3 className="text-sm font-medium">
          {i18nText ? <Translate id={i18nText} /> : text}
        </h3>
      </Tooltip>
    </>
  );
}

export default IconWithTooltip;
