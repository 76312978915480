import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName, I18nText } from '../../../../../types';

import { ConfirmModalButton } from '../../../../../helpers/buttons/ConfirmModalButton';

import { invoicesKeys } from '../../../../../locales/keys';

import { BillCache } from '../../../BillCache';

import { BillUUID, CancelBillIndexCacheKey } from '../../../billsTypes';

import { useCancelBill } from '../../../hooks/useCancelBill';

import {
  CANCEL_BILL_QUERY,
  CancelBillQueryResponse
} from '../../../queries/cancelBill.query';

interface CancelBillConfirmModalButtonProps {
  disabled: boolean;
  indexCacheKey?: CancelBillIndexCacheKey;
  billUuid: BillUUID;
  className?: ClassName;
  i18nButtonText?: I18nText;
  icon?: IconsEnum;
}

function CancelBillConfirmModalButton({
  disabled,
  indexCacheKey,
  billUuid,
  className,
  icon,
  i18nButtonText
}: CancelBillConfirmModalButtonProps) {
  const { cancelBill, cancelBillLoading, cancelBillErrorMessage } =
    useCancelBill<CancelBillQueryResponse>({
      query: CANCEL_BILL_QUERY,
      indexCacheKey,
      showCacheKey: BillCache.showCacheKey(billUuid)
    });

  const handleCancelBill = useCallback(
    () => cancelBill({ uuid: billUuid }),
    [cancelBill, billUuid]
  );

  return (
    <ConfirmModalButton
      className={
        className ||
        'flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap'
      }
      disabled={cancelBillLoading || disabled}
      errorMessage={cancelBillErrorMessage}
      i18nButtonText={i18nButtonText || invoicesKeys.cancel}
      i18nSubmitText={invoicesKeys.cancel}
      i18nText={invoicesKeys.messages.areYouSureYouWantToCancelInvoice}
      i18nTitle={invoicesKeys.cancel}
      iconClassName="h-5 w-5 mr-2"
      icon={icon}
      isLoading={cancelBillLoading}
      modalIcon={IconsEnum.EXCLAMATION}
      modalIconClassName="h-6 w-6 text-red-600"
      onSubmit={handleCancelBill}
    />
  );
}

export default CancelBillConfirmModalButton;
