import { useCallback, useState } from 'react';
import includes from 'lodash/includes';

import { useCurrentUser } from '../../../../../../../../auth/hooks/useAuth/useAuth';

import { ItemIndexSidebarTeamItemUser } from '../../../../../../components/sidebars/ItemIndexSidebar/components/ItemIndexSidebarTeam';
import {
  UsersTabsType,
  UsersTabType
} from '../../../../../../../users/usersTypes';

import { ID } from '../../../../../../../../types';

interface InviteUsersModalBodyOptions {
  availableTabs?: UsersTabsType;
  onSelectUser: (user: ItemIndexSidebarTeamItemUser) => void;
  onDeselectUser: (user: ItemIndexSidebarTeamItemUser) => void;
}

function useInviteUsersModalBody({
  availableTabs,
  onSelectUser,
  onDeselectUser
}: InviteUsersModalBodyOptions) {
  const currentUser = useCurrentUser();

  const handleCheckUser = useCallback<
    (user: ItemIndexSidebarTeamItemUser, checked: boolean) => void
  >(
    (user: ItemIndexSidebarTeamItemUser, checked: boolean) =>
      checked ? onSelectUser(user) : onDeselectUser(user),
    [onSelectUser, onDeselectUser]
  );

  const handleCheckAllUsers = useCallback<
    (users: ItemIndexSidebarTeamItemUser[], checked: boolean) => void
  >(
    (users: ItemIndexSidebarTeamItemUser[], checked: boolean) =>
      checked
        ? users?.forEach((user) => onSelectUser(user))
        : users?.forEach((user) => onDeselectUser(user)),
    [onSelectUser, onDeselectUser]
  );

  const [openedTab, setOpenedTab] = useState<UsersTabType>(() => {
    if (
      includes(availableTabs, 'workers') &&
      currentUser.hasPermissions('read_workers_tab')
    ) {
      return 'workers';
    }

    if (
      includes(availableTabs, 'clients') &&
      currentUser.hasPermissions('read_clients_tab')
    ) {
      return 'clients';
    }

    return null;
  });

  const [fullName, setFullName] = useState<string>('');

  const [groupIds, setGroupIds] = useState<ID[]>([]);

  const [teamIds, setTeamIds] = useState<ID[]>([]);

  const handleTabChange = useCallback<(tab: UsersTabType) => void>(
    (tab) => setOpenedTab(tab),
    [setOpenedTab]
  );

  const handleChangeFullName = useCallback<
    (value: { fullName: string }) => void
  >((value) => setFullName(value?.fullName), [setFullName]);

  const handleChangeGroupIdsFilter = useCallback<
    (value: { groupIds: ID[] }) => void
  >((value) => setGroupIds(value?.groupIds), [setGroupIds]);

  const handleChangeTeamIdsFilter = useCallback<
    (value: { teamIds: ID[] }) => void
  >((value) => setTeamIds(value?.teamIds), [setTeamIds]);

  return {
    fullName,
    groupIds,
    handleChangeFullName,
    handleChangeGroupIdsFilter,
    handleChangeTeamIdsFilter,
    handleCheckUser,
    handleCheckAllUsers,
    handleTabChange,
    openedTab,
    teamIds
  };
}

export default useInviteUsersModalBody;
