import isEmpty from 'lodash/isEmpty';

import { MessagesListMessageFilesMessage } from './MessagesListMessageFiles.types';

import { MessagesListMessageFile } from '../MessagesListMessageFile';

import { Files } from '../../../../../../../utils/Files';

interface MessagesListMessageFilesProps {
  message: MessagesListMessageFilesMessage;
}

function MessagesListMessageFiles({ message }: MessagesListMessageFilesProps) {
  const fileAttachments = message.fileAttachments?.filter(
    (fileAttachment) => !Files.isImage(fileAttachment.file)
  );

  if (isEmpty(fileAttachments)) {
    return null;
  }

  return (
    <>
      {fileAttachments.map((fileAttachment) => (
        <MessagesListMessageFile
          key={fileAttachment.uuid}
          fileAttachment={fileAttachment}
          message={message}
          tooltipSingleton
        />
      ))}
    </>
  );
}

export default MessagesListMessageFiles;
