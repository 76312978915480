import {
  BillGqlError,
  BillUUID,
  CheckBillGqlQuery,
  CheckBillGqlStatus,
  CheckBillIndexCacheKey,
  CheckBillShowCacheKey
} from '../../billsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { BillCache } from '../../BillCache';

interface CheckBillOptions {
  query: CheckBillGqlQuery;
  indexCacheKey?: CheckBillIndexCacheKey;
  showCacheKey?: CheckBillShowCacheKey;
}

export interface CheckBillResponse<CheckBillRecordType> {
  checkBill: {
    status: CheckBillGqlStatus;
    recordUuid: BillUUID;
    record: CheckBillRecordType;
    errors: CheckBillError;
  };
}

export interface CheckBillInput {
  uuid: BillUUID;
}

export interface CheckBillError {
  fullMessages: BillGqlError;
}

const action = 'checkBill';
const pluralScope = BillCache.indexCacheKey();

function useCheckBill<CheckBillRecordType>({
  query,
  indexCacheKey = pluralScope,
  showCacheKey
}: CheckBillOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    CheckBillInput,
    CheckBillResponse<CheckBillRecordType>,
    CheckBillError,
    CheckBillRecordType
  >({ action, pluralScope: indexCacheKey, scope: showCacheKey, query });

  return {
    checkBillData: updateQueryData,
    checkBillError: updateQueryError,
    checkBillLoading: updateQueryLoading,
    checkBillErrorMessage: updateQueryErrorMessage,
    checkBill: updateQuery,
    checkBillReset: updateQueryReset
  };
}

export default useCheckBill;
