import { FetchItemsFilters } from '../../../../../../../../types';
import { IndexRequestAction } from '../../reducers/indexRequestReducer';

import { CHANGE_ITEMS_FILTERS } from '../../infiniteIndexRequestConstants';

function changeItemsFiltersAction(
  changedFilters: Partial<FetchItemsFilters>,
  removeFilters: string[]
): IndexRequestAction {
  return {
    type: CHANGE_ITEMS_FILTERS,
    changedFilters,
    removeFilters
  };
}

export default changeItemsFiltersAction;
