import { gql } from 'graphql-request';

import { MayBe } from '../../../types';

import {
  UserID,
  UserUUID,
  UserCreatedAt,
  UserFullName,
  UserName,
  UserSurname,
  UserEmail,
  UserImageUUID,
  UserImageFile,
  UserNanoID,
  UserFinanceRoleName,
  UserUpdatedAt,
  UserClient,
  UserCurrentTeamNanoID
} from '../usersTypes';

export interface SearchUsersQueryResponse {
  id: UserID;
  uuid: UserUUID;
  nanoId: UserNanoID;
  fullName: UserFullName;
  name: UserName;
  surname: UserSurname;
  email: UserEmail;
  financeRoleName: UserFinanceRoleName;
  createdAt: UserCreatedAt;
  updatedAt: UserUpdatedAt;
  client: UserClient;
  currentTeam: MayBe<{
    nanoId: UserCurrentTeamNanoID;
  }>;
  image: {
    uuid: UserImageUUID;
    file: UserImageFile;
  };
}

export const SEARCH_USERS_QUERY = gql`
  query SearchUsers(
    $filters: UsersFilters
    $sort: [UsersSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    users(filters: $filters, sort: $sort, offset: $offset, limit: $limit) {
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
        totalCount
        totalPages
      }
      nodes {
        id
        uuid
        nanoId
        fullName
        name
        surname
        email
        financeRoleName
        createdAt
        updatedAt
        client
        currentTeam {
          nanoId
        }
        image {
          uuid
          file
        }
      }
    }
  }
`;
