import {
  FetchProjectsCacheKey,
  FetchProjectsFilters,
  FetchProjectsGqlQuery,
  FetchProjectsLimit,
  FetchProjectsSort
} from '../../projectsTypes';
import {
  INITIAL_PROJECTS_FILTERS,
  INITIAL_PROJECTS_SORT,
  INITIAL_PROJECTS_LIMIT
} from '../../projectsConstants';
import {
  InfiniteIndexQueryBaseNodeType,
  useInfiniteIndexQuery
} from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

interface ProductsOptions {
  cacheKey: FetchProjectsCacheKey;
  query: FetchProjectsGqlQuery;
  initialFilters?: FetchProjectsFilters;
  initialSort?: FetchProjectsSort;
  initialLimit?: FetchProjectsLimit;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

const scope = 'projects';

function useProjects<ProjectItemType extends InfiniteIndexQueryBaseNodeType>({
  cacheKey,
  query,
  initialFilters = INITIAL_PROJECTS_FILTERS,
  initialSort = INITIAL_PROJECTS_SORT,
  initialLimit = INITIAL_PROJECTS_LIMIT,
  options = {}
}: ProductsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    clearItemsFilters,
    filterItems,
    sortItems,
    limitItems,
    changeItemsFilters
  } = useInfiniteIndexQuery<ProjectItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialSort,
    options,
    query,
    scope
  });

  return {
    projectsData: data,
    projects: items,
    projectsError: itemsError,
    projectsTotalCount: itemsTotalCount,
    projectsFetched: isFetched,
    projectsLoading: isLoading,
    projectsFetchingNextPage: isFetchingNextPage,
    projectsIsPlaceholderData: isPlaceholderData,
    projectsFilters: currentFilters,
    projectsSort: currentSort,
    projectsPage: currentPage,
    projectsLimit: currentLimit,
    projectsHasNextPage: hasNextPage,
    updateProjectCache: updateItemCache,
    loadMoreProjects: loadMoreItems,
    filterProjects: filterItems,
    changeProjectsFilters: changeItemsFilters,
    clearProjectsFilters: clearItemsFilters,
    sortProjects: sortItems,
    limitProjects: limitItems
  };
}

export default useProjects;
