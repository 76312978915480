import { MatchResultUUID } from '../matchResults/matchResultsTypes';

const baseUrl = 'products';

export class ProductBffUrl {
  static matchResult(matchResultUuid: MatchResultUUID) {
    return `${baseUrl}/match_result/${matchResultUuid}`;
  }
  static elasticSearch() {
    return `${baseUrl}/elastic_search`;
  }
  static elasticSearchSuggest() {
    return `${baseUrl}/elastic_search_suggest`;
  }
}
