import React, { useMemo } from 'react';
import find from 'lodash/find';
import toString from 'lodash/toString';

import { ProductCategoryID } from '../../../products/productsTypes';

import { useProductCategoriesAll } from '../../../products/hooks/useProductCategoriesAll';

import { Loading } from '../../../../helpers/Loading';

interface ProductsCategoryLocalizedNameByIdProps {
  productCategoryId: ProductCategoryID;
}
function ProductsCategoryLocalizedNameById({
  productCategoryId
}: ProductsCategoryLocalizedNameByIdProps) {
  const { productCategories, productCategoriesFetched } =
    useProductCategoriesAll();

  const category = useMemo(() => {
    return find(productCategories, ['id', toString(productCategoryId)]);
  }, [productCategories, productCategoryId]);

  return (
    <Loading size="xs" loaded={productCategoriesFetched}>
      {category ? <>{category.localizedName}</> : null}
    </Loading>
  );
}

export default ProductsCategoryLocalizedNameById;
