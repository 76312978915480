import { gql } from 'graphql-request';

import { BillID, BillUUID } from '../billsTypes';

export interface CheckBillQueryResponse {
  id: BillID;
  uuid: BillUUID;
}

export const CHECK_BILL_QUERY = gql`
  mutation CheckBill($uuid: ID!) {
    checkBill(input: { uuid: $uuid }) {
      recordUuid
      status
      record {
        id
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
