import { Fragment, ReactNode } from 'react';
import range from 'lodash/range';
import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

interface ProductsBlockListLoadingGridSkeletonProps {
  children?: ReactNode;
  loaded?: boolean;
  itemsCount?: number;
}

function ProductsBlockListLoadingGridSkeleton({
  children,
  loaded,
  itemsCount = 5
}: ProductsBlockListLoadingGridSkeletonProps) {
  if (loaded) return <Fragment>{children}</Fragment>;

  return (
    <div className="grid grid-cols-auto-fill grid-cell-min-36 sm:grid-cell-min-40 gap-2">
      {range(itemsCount).map((key) => (
        <div
          key={key}
          className="w-80 max-w-full mx-auto relative rounded-md group"
        >
          <div className="rounded-md overflow-hidden aspect-w-1 aspect-h-1">
            <Skeleton height="100%" containerClassName="flex" />
          </div>

          <div className="text-sm p-3">
            <div className="font-medium truncate">
              <Skeleton width={160} />
            </div>
            <div className="text-gray-600 dark:text-gray-500 truncate">
              <Skeleton width={120} />
            </div>
            <div className="mt-3 flex justify-between items-center relative z-5">
              <Skeleton width={25} height={25} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProductsBlockListLoadingGridSkeleton;
