import some from 'lodash/some';

import {
  InfiniteIndexQueryBaseNodeType,
  InfiniteIndexQueryData,
  UpdateItemCacheSelector
} from '../../../../useInfiniteIndexQuery.types';

interface GetItemIndexesOptions {
  data: InfiniteIndexQueryData<InfiniteIndexQueryBaseNodeType>;
  selector: UpdateItemCacheSelector;
  scope: string;
}

function getItemIndexes({ data, selector, scope }: GetItemIndexesOptions) {
  let pageIndex = -1;
  let itemIndex = -1;

  some(data?.pages, (page, currentPageIndex) => {
    return some(page?.[scope]?.nodes, (currentItem, currentItemIndex) => {
      if (currentItem?.uuid && currentItem.uuid === selector?.uuid) {
        pageIndex = currentPageIndex;
        itemIndex = currentItemIndex;
        return true;
      }
    });
  });

  return { pageIndex, itemIndex };
}

export default getItemIndexes;
