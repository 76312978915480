import React, { useCallback, useState } from 'react';
import includes from 'lodash/includes';

import { I18nText, ID, UUID } from '../../../../../../../../types';

import {
  CheckableUsersListItemUserType,
  CheckableUsersListItem
} from '../CheckableUsersListItem';
import { Checkbox } from '../../../../../../../../helpers/Checkbox';

interface CheckableUsersListProps {
  invitedUserUuids?: UUID[];
  onCheck?: (user: CheckableUsersListItemUserType, checked: boolean) => void;
  onCheckAll?: (
    users: CheckableUsersListItemUserType[],
    checked: boolean
  ) => void;
  onRemoveRecentUser?: ({ uuid }: { uuid: UUID }) => void;
  onRemoveRecentUserDisabled?: boolean;
  selectedUserIds: ID[];
  users: CheckableUsersListItemUserType[];
  alreadySelectedI18nTitle?: I18nText;
  withoutCheckAll?: boolean;
}

function CheckableUsersList({
  invitedUserUuids,
  onCheck,
  onCheckAll,
  onRemoveRecentUser,
  onRemoveRecentUserDisabled,
  selectedUserIds,
  users,
  alreadySelectedI18nTitle,
  withoutCheckAll
}: CheckableUsersListProps) {
  const [checkedAll, setCheckedAll] = useState<boolean>(false);

  const handleCheckAll = useCallback(
    (checked: boolean) => {
      onCheckAll?.(users, checked);
      setCheckedAll(checked);
    },
    [users, onCheckAll, setCheckedAll]
  );

  return (
    <ul className="-mx-2 max-h-96 overflow-y-auto p-2.5">
      {!withoutCheckAll && (
        <li className="group -mx-px rounded-lg bg-gray-500 bg-opacity-0 hover:bg-opacity-10">
          <div className="flex items-center space-x-2">
            <div className="flex items-center">
              <Checkbox checked={checkedAll} onChange={handleCheckAll} />
            </div>
          </div>
        </li>
      )}

      {users?.map((user) => (
        <CheckableUsersListItem
          checked={includes(selectedUserIds, user.id)}
          disabled={includes(invitedUserUuids, user?.uuid)}
          key={user.uuid}
          onCheck={onCheck}
          onRemoveRecentUser={onRemoveRecentUser}
          user={user}
          alreadySelectedI18nTitle={alreadySelectedI18nTitle}
          onRemoveRecentUserDisabled={onRemoveRecentUserDisabled}
        />
      ))}
    </ul>
  );
}

export default CheckableUsersList;
