import { useCallback } from 'react';
import { useQueryClient, QueryKey } from 'react-query';
import isEmpty from 'lodash/isEmpty';

import {
  AddInfiniteIndexQueryItemCacheAction,
  InfiniteIndexQueryBaseNodeType,
  InfiniteIndexQueryData
} from '../../useInfiniteIndexQuery.types';

import { getUpdatedQueryData } from './utils/getUpdatedQueryData';

interface UpdateInfiniteIndexQueryItemCacheOptions {
  fullCacheKey: QueryKey;
  scope: string;
}

function useAddInfiniteIndexQueryItemCache<
  ItemType extends InfiniteIndexQueryBaseNodeType
>({ fullCacheKey, scope }: UpdateInfiniteIndexQueryItemCacheOptions) {
  const queryClient = useQueryClient();

  return useCallback<AddInfiniteIndexQueryItemCacheAction<ItemType>>(
    ({ addItem, atStart }) => {
      queryClient.cancelQueries(fullCacheKey);

      const previousQueryData =
        queryClient.getQueryData<InfiniteIndexQueryData<ItemType>>(
          fullCacheKey
        );

      if (!previousQueryData || isEmpty(previousQueryData?.pages)) {
        return null;
      }

      const updatedQueryData = getUpdatedQueryData<ItemType>({
        addItem,
        previousQueryData,
        scope,
        atStart
      });

      if (!updatedQueryData) {
        return null;
      }

      queryClient.setQueryData<InfiniteIndexQueryData<ItemType>>(
        fullCacheKey,
        updatedQueryData
      );

      return () => {
        queryClient.setQueryData<InfiniteIndexQueryData<ItemType>>(
          fullCacheKey,
          previousQueryData
        );
      };
    },
    [fullCacheKey, queryClient, scope]
  );
}

export default useAddInfiniteIndexQueryItemCache;
