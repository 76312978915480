import { PAGE_ITEMS_LIMIT } from '../../../../common/constants';

import {
  FetchInviteUserSearchesFilters,
  FetchInviteUserSearchesSort,
  FetchInviteUserSearchesPage,
  FetchInviteUserSearchesLimit,
  InviteUserSearchType
} from '../../inviteUserSearchesTypes';

import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

interface InviteUserSearchesOptions {
  cacheKey: string;
  query: string;
  initialFilters?: FetchInviteUserSearchesFilters;
  initialSort?: FetchInviteUserSearchesSort;
  initialPage?: FetchInviteUserSearchesPage;
  initialLimit?: FetchInviteUserSearchesLimit;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

const scope = 'inviteUserSearches';

function useInviteUserSearches<ItemType = InviteUserSearchType>({
  cacheKey,
  query,
  initialFilters = {},
  initialSort = ['CREATED_AT_DESC'],
  initialPage = 1,
  initialLimit = PAGE_ITEMS_LIMIT,
  options = {}
}: InviteUserSearchesOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    clearItemsFilters,
    filterItems,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems
  } = useIndexQuery<ItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    options,
    query,
    scope
  });

  return {
    inviteUserSearchesData: data,
    inviteUserSearches: items,
    inviteUserSearchesError: itemsError,
    inviteUserSearchesTotalCount: itemsTotalCount,
    inviteUserSearchesFetched: isFetched,
    inviteUserSearchesLoading: isLoading,
    inviteUserSearchesIsPlaceholderData: isPlaceholderData,
    inviteUserSearchesFilters: currentFilters,
    inviteUserSearchesSort: currentSort,
    inviteUserSearchesPage: currentPage,
    inviteUserSearchesLimit: currentLimit,
    filterInviteUserSearches: filterItems,
    clearInviteUserSearchesFilters: clearItemsFilters,
    sortInviteUserSearches: sortItems,
    paginateInviteUserSearches: paginateItems,
    limitInviteUserSearches: limitItems,
    prefetchInviteUserSearches: prefetchItems
  };
}

export default useInviteUserSearches;
