import React, { useCallback, useState } from 'react';

import { IconsEnum } from '../../assets/icons/types';
import { UserEmail } from '../../main/users/usersTypes';

import { PureTooltipIconButtonHelper } from '../buttons/PureTooltipIconButtonHelper';

import { copyToClipboard } from '../../utils/copyToClipboard';

import { TooltipPlacement } from '../tooltips/tooltipsConstants';

interface CopyUserEmailButtonProps {
  email: UserEmail;
}

function CopyUserEmailButton({ email }: CopyUserEmailButtonProps) {
  const [copied, setCopied] = useState<boolean>(false);

  const handleCopy = useCallback<() => void>(() => {
    const result = copyToClipboard(email);
    if (result) {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  }, [email, setCopied]);

  return (
    <PureTooltipIconButtonHelper
      icon={copied ? IconsEnum.CHECK : IconsEnum.COPY}
      className="py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
      iconClassName="h-5 w-5"
      onClick={handleCopy}
      tooltipText={`Copy ${email}`}
      tooltipPlacement={TooltipPlacement.TOP}
    />
  );
}

export default CopyUserEmailButton;
