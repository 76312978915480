import { useMemo } from 'react';

import { ID } from '../../../../../types';
import { CategoryType } from '../../../categoriesTypes';
import { FiltersProps } from '../../../../../helpers/filters/hooks/useFilters';

import { useFinPaginatedCategories } from '../../../hooks/useFinPaginatedCategories';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { MultiSelectFilter } from '../../../../../helpers/filters/MultiSelectFilter';

import { GET_CATEGORIES_QUERY } from '../../../pages/CategoriesIndexPage';

interface CategoriesFilterProps extends FiltersProps<ID[]> {
  i18nPlaceholder?: string;
  scope: string;
}

function CategoriesFilter({
  i18nPlaceholder,
  name,
  onChange,
  scope,
  value
}: CategoriesFilterProps) {
  const { categories, categoriesError, categoriesLoading } =
    useFinPaginatedCategories<CategoryType>({
      cacheKey: scope,
      query: GET_CATEGORIES_QUERY,
      initialFilters: { scope: { eq: scope } },
      initialLimit: 1000
    });

  const data = useMemo(() => {
    return categories.map(({ id: value, name: label }) => ({ value, label }));
  }, [categories]);

  return (
    <>
      <MultiSelectFilter
        data={data}
        i18nPlaceholder={i18nPlaceholder}
        name={name}
        onChange={onChange}
        optionsLoading={categoriesLoading}
        value={value}
      />
      <AlertMessage addClassName="mt-2" message={categoriesError} />
    </>
  );
}

export default CategoriesFilter;
