import isString from 'lodash/isString';
import endsWith from 'lodash/endsWith';
import replace from 'lodash/replace';
import { ModelingCategorySlug } from '../../modelingCategoriesTypes';

import { modelingCategorySlugDetectSingleSearchEnding } from '../../modelingCategoriesConstants';

function normalizeModelingCategorySlug(
  modelingCategorySlug: ModelingCategorySlug
): ModelingCategorySlug {
  if (
    isString(modelingCategorySlug) &&
    endsWith(modelingCategorySlug, modelingCategorySlugDetectSingleSearchEnding)
  ) {
    return replace(
      modelingCategorySlug,
      modelingCategorySlugDetectSingleSearchEnding,
      ''
    );
  }

  return modelingCategorySlug;
}

export default normalizeModelingCategorySlug;
