import { FetchMessagesCacheKey } from '../../../../../../../../../messagesTypes';

import { CheckBillButton } from '../../../../../../../../../../bills/components/buttons/CheckBillButton';

import { ItemMessagesListBillMessageItem } from '../../../../ItemMessagesListBillMessage.types';
import { ItemMessagesListMessageMenuDropdownMessage } from '../../../../../ItemMessagesListMessageMenuDropdown';

interface BillMessageDropdownCheckBillButtonProps {
  message: ItemMessagesListBillMessageItem &
    ItemMessagesListMessageMenuDropdownMessage;
  messagesCacheKey: FetchMessagesCacheKey;
}

function BillMessageDropdownCheckBillButton({
  message,
  messagesCacheKey
}: BillMessageDropdownCheckBillButtonProps) {
  return (
    <CheckBillButton
      billUuid={message.bill.uuid}
      indexCacheKey={messagesCacheKey}
    />
  );
}

export default BillMessageDropdownCheckBillButton;
