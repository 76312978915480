import { ClassName } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import { MessagesListMessageImageMaterialButtons } from './components/MessagesListMessageImageMaterialButtons';
import {
  MessagesListMessageImageHandleOpenLightbox,
  MessagesListMessageImageSelectedMaterialAttachment
} from '../MessagesListMessageImage';

import {
  ImageHelper,
  FileAttachmentItemImageVersions
} from '../../../../../../../helpers/ImageHelper';
import { LinkHelper } from '../../../../../../../helpers/links/LinkHelper';
import { Icon } from '../../../../../../../helpers/Icon';

interface MessagesListMessageImageMaterialProps {
  linkClassName: ClassName;
  imageClassName: ClassName;
  attachment: MessagesListMessageImageSelectedMaterialAttachment;
  handleOpenLightbox: MessagesListMessageImageHandleOpenLightbox;
  tooltipSingleton?: boolean;
}

function MessagesListMessageImageMaterial({
  linkClassName,
  imageClassName,
  attachment,
  handleOpenLightbox,
  tooltipSingleton
}: MessagesListMessageImageMaterialProps) {
  return (
    <div className={linkClassName}>
      <MessagesListMessageImageMaterialButtons attachment={attachment} />
      <LinkHelper
        href={attachment.material.image.file}
        target="_blank"
        onClick={handleOpenLightbox}
      >
        <ImageHelper
          tooltipAlt
          tooltipSingleton={tooltipSingleton}
          className={imageClassName}
          src={attachment.material.image.file}
          alt={attachment.material.image.name}
          version={FileAttachmentItemImageVersions.BigThumb538x435}
        />
        <div className="absolute bottom-0 left-0 p-1 bg-teal-600 text-white flex rounded-bl rounded-tr">
          <Icon icon={IconsEnum.MATERIAL_OUTLINE} className="w-5 h-5" />
        </div>
      </LinkHelper>
    </div>
  );
}

export default MessagesListMessageImageMaterial;
