import { BffApiUpdateItemError } from '../../../../types';
import { CreateMessageFromAiProductsSearchByAngleCacheKeys } from '../../messagesTypes';
import { TaskID } from '../../../tasks/tasksTypes';
import { ProjectID } from '../../../projects/projectsTypes';
import { MatchResultItemAngleId } from '../../../matchResults/matchResultsTypes';
import { ProductID } from '../../../products/productsTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { MessageBffUrl } from '../../MessageBffUrl';

interface CreateMessageFromAiProductsSearchByAngleOptions {
  cacheKeys?: CreateMessageFromAiProductsSearchByAngleCacheKeys;
}

export type CreateMessageFromAiProductsSearchByAngleInput = {
  projectId?: ProjectID;
  taskId?: TaskID;
  angleId: MatchResultItemAngleId;
  productId: ProductID;
};

export type CreateMessageFromAiProductsSearchByAngleError =
  BffApiUpdateItemError;

function useCreateMessageFromAiProductsSearchByAngle<
  CreateMessageFromAiProductsSearchByAngleResponseType
>({ cacheKeys }: CreateMessageFromAiProductsSearchByAngleOptions) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<
    CreateMessageFromAiProductsSearchByAngleInput,
    CreateMessageFromAiProductsSearchByAngleResponseType,
    CreateMessageFromAiProductsSearchByAngleError
  >({ cacheKeys, url: MessageBffUrl.createFromAiProductsSearchByAngle() });

  return {
    createMessageFromAiProductsSearchByAngleData: postQueryData,
    createMessageFromAiProductsSearchByAngleError: postQueryError,
    createMessageFromAiProductsSearchByAngleLoading: postQueryLoading,
    createMessageFromAiProductsSearchByAngleErrorMessage: postQueryErrorMessage,
    createMessageFromAiProductsSearchByAngle: postQuery,
    createMessageFromAiProductsSearchByAngleReset: postQueryReset
  };
}

export default useCreateMessageFromAiProductsSearchByAngle;
