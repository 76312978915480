import { gql } from 'graphql-request';

export const GET_TEAMS_FILTER_QUERY = gql`
  query TeamsFilter(
    $filters: TeamsFilters
    $sort: [TeamsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    teams(filters: $filters, sort: $sort, offset: $offset, limit: $limit) {
      nodes {
        id
        uuid
        name
        teamType
        createdAt
        updatedAt
      }
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
        totalCount
        totalPages
      }
    }
  }
`;
