import {
  FetchProductsCacheKey,
  FetchProductsFilters,
  FetchProductsLimit,
  ProductBrandName,
  ProductBrandUUID,
  ProductBrandID,
  ProductCreatedAt,
  ProductBlocked,
  ProductFavorite,
  ProductID,
  ProductName,
  ProductNda,
  ProductUUID,
  ProductImageUUID,
  ProductImageFile,
  ProductBrandLocalizedName,
  ProductNanoID,
  ProductRenderTypeID,
  ProductRenderTypeName,
  ProductTagID,
  ProductTagName,
  ProductAngleId
} from '../../productsTypes';
import { MatchResultUUID } from '../../../matchResults/matchResultsTypes';

import { useBffInfiniteIndexQuery } from '../../../common/hooks/base/reactQuery/useBffInfiniteIndexQuery';

import {
  INITIAL_PRODUCTS_FILTERS,
  INITIAL_PRODUCTS_LIMIT
} from '../../productsConstants';

import { ProductBffUrl } from '../../ProductBffUrl';

export interface FetchMatchResultProductsProductType {
  angleId: ProductAngleId;

  blocked: ProductBlocked;
  favorite: ProductFavorite;
  id: ProductID;
  uuid: ProductUUID;
  nanoId: ProductNanoID;
  name: ProductName;
  nda: ProductNda;
  sku: ProductUUID;
  createdAt: ProductCreatedAt;
  updatedAt: ProductCreatedAt;
  renderTypes: {
    id: ProductRenderTypeID;
    name: ProductRenderTypeName;
  }[];
  image: {
    uuid: ProductImageUUID;
    file: ProductImageFile;
  };
  brand: {
    id: ProductBrandID;
    uuid: ProductBrandUUID;
    name: ProductBrandName;
    localizedName: ProductBrandLocalizedName;
  };
  tags: {
    id: ProductTagID;
    localizedName: ProductTagName;
  }[];
}

interface MatchResultProductsQueryOptions {
  staleTime?: number;
  enabled?: boolean;
  enabledPlaceholder?: boolean;
}

interface MatchResultProductsOptions {
  cacheKey: FetchProductsCacheKey;
  matchResultUuid: MatchResultUUID;
  initialFilters?: FetchProductsFilters;
  initialLimit?: FetchProductsLimit;
  options?: MatchResultProductsQueryOptions;
}

const scope = 'products';

function useMatchResultProducts({
  cacheKey,
  matchResultUuid,
  initialFilters = INITIAL_PRODUCTS_FILTERS,
  initialLimit = INITIAL_PRODUCTS_LIMIT,
  options
}: MatchResultProductsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    limitItems,
    loadMoreItems
  } = useBffInfiniteIndexQuery<FetchMatchResultProductsProductType>({
    cacheKey,
    scope,
    url: ProductBffUrl.matchResult(matchResultUuid),
    initialFilters,
    initialLimit,
    options
  });

  return {
    productsData: data,
    products: items,
    productsError: itemsError,
    productsErrorMessage: itemsErrorMessage,
    productsTotalCount: itemsTotalCount,
    productsFetched: isFetched,
    productsLoading: isLoading,
    productsFetchingNextPage: isFetchingNextPage,
    productsIsPlaceholderData: isPlaceholderData,
    productsFilters: currentFilters,
    productsSort: currentSort,
    productsPage: currentPage,
    productsLimit: currentLimit,
    hasNextProductsPage: hasNextPage,
    updateProductCache: updateItemCache,
    filterProducts: filterItems,
    changeProductsFilters: changeItemsFilters,
    clearProductsFilters: clearItemsFilters,
    sortProducts: sortItems,
    loadMoreProducts: loadMoreItems,
    limitProducts: limitItems,
    prefetchProducts: prefetchItems
  };
}

export default useMatchResultProducts;
